import {inject, Injectable} from '@angular/core';
import {Country} from '@nx-angular-standalone/shared/model';
import {
    BehaviorSubject,
    catchError,
    finalize,
    map,
    Observable,
    of,
    switchMap,
    tap,
} from 'rxjs';

import {CountriesApi} from '../api/countries.api';

@Injectable()
export class CountriesService {
    private readonly _countriesApi = inject(CountriesApi);
    private readonly search$ = new BehaviorSubject(null);

    readonly loading$ = new BehaviorSubject<boolean>(false);

    getCountries(): Observable<Country[]> {
        return this.search$.pipe(
            tap(() => this.loading$.next(true)),
            switchMap(() =>
                this._countriesApi.get().pipe(
                    catchError(() => of()),
                    map(countries => countries.sort((a, b) => (a.id > b.id ? 1 : -1))),
                    finalize(() => this.loading$.next(false)),
                ),
            ),
        );
    }
}
