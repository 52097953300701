import {environment} from '@nx-angular-standalone/shared/environments';

export const addYandexMetrica = (): void => {
    if (!environment.analytics.ym) {
        return;
    }

    const script = document.createElement('script');
    const noscript = document.createElement('noscript');

    script.type = 'text/javascript';
    script.innerHTML =
        '(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n' +
        '   m[i].l=1*new Date();\n' +
        '   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}\n' +
        '   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n' +
        '   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");\n' +
        '\n' +
        `   ym(${environment.analytics.ym}, "init", {
` +
        '        clickmap:true,\n' +
        '        trackLinks:true,\n' +
        '        accurateTrackBounce:true,\n' +
        '        webvisor:true\n' +
        '   });';
    document.head.appendChild(script);

    noscript.innerHTML = `<div><img src="https://mc.yandex.ru/watch/${environment.analytics.ym}" style="position:absolute; left:-9999px;" alt="" /></div>`;
    document.head.appendChild(noscript);
};
