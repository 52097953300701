import {inject} from '@angular/core';
import {ActivatedRoute, NavigationEnd} from '@angular/router';
import {MetaService} from '@nx-angular-standalone/shared/data-access';
import {Observable, tap} from 'rxjs';

import {injectNavigationEnd} from './navigation-end';

export function injectMetaTags(): Observable<NavigationEnd> {
    const activatedRoute = inject(ActivatedRoute);
    const metaService = inject(MetaService);

    return injectNavigationEnd().pipe(
        tap(() => {
            const {meta, metaOg, noIndex} = activatedRoute.snapshot.data ?? {};

            metaService.update(meta, metaOg, noIndex);
        }),
    );
}
