import {DOCUMENT} from '@angular/common';
import {inject, Injectable} from '@angular/core';
import {Meta, MetaDefinition, Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {ENVIRONMENT} from '@nx-angular-standalone/shared/environments';
import {MetaConfig, MetaConfigOg} from '@nx-angular-standalone/shared/model';
import {
    getCurrentDate,
    META_CONFIG,
    META_CONFIG_OG,
    WEBSITE_JSON_LD_ID,
} from '@nx-angular-standalone/shared/util';

@Injectable({providedIn: 'root'})
export class MetaService {
    private readonly router = inject(Router);
    private readonly title = inject(Title);
    private readonly meta = inject(Meta);
    private readonly document = inject(DOCUMENT);
    private readonly environment = inject(ENVIRONMENT);
    private readonly metaConfig = inject(META_CONFIG);
    private readonly metaConfigOg = inject(META_CONFIG_OG);
    private _date = '';

    update(
        metaConfig?: Partial<MetaConfig>,
        metaConfigOg?: Partial<MetaConfigOg>,
        noIndex: boolean = false,
    ): void {
        const config: MetaConfig = {...this.metaConfig, ...metaConfig};
        const configOg: MetaConfigOg = {...this.metaConfigOg, ...metaConfigOg};

        this.setCanonicalUrl(config.url);
        this.title.setTitle(
            `${config.title} ${this._date} | ${this.environment.siteName}`,
        );

        this.setMetaProperty('description', config.description);
        this.setMetaProperty(
            'og:title',
            `${configOg.title ?? config.title} | ${this.environment.siteName}`,
        );
        this.setMetaProperty(
            'og:description',
            configOg.description ?? config.description,
        );
        this.setMetaProperty('og:type', configOg.type);
        this.setMetaProperty(
            'og:site_name',
            configOg.siteName ?? this.environment.siteName,
        );
        this.setMetaProperty('og:image', `${this.getBaseUrl()}${configOg.image}`);
        this.setMetaProperty('og:image:type', configOg.imageType);
        this.setMetaProperty('og:image:width', configOg.imageWidth);
        this.setMetaProperty('og:image:height', configOg.imageHeight);

        if (noIndex) {
            this.meta.addTag({name: 'robots', content: 'noindex'});
        } else {
            this.meta.removeTag('name="robots"');
        }
    }

    jsonLD(json: Record<string, string>, id: string): void {
        if (this.document.getElementById(id)) {
            this.document.getElementById(id)!.remove();
        }

        const script = this.document.createElement('script');

        json['url'] =
            id === WEBSITE_JSON_LD_ID ? this.getBaseUrl() : this.getCanonicalURL();
        script.type = 'application/ld+json';
        script.id = id;
        script.text = JSON.stringify(json);

        this.document.head.appendChild(script);
    }

    getBaseUrl(): string {
        const {baseUrl} = this.environment;
        const url = baseUrl.includes('/backend/api')
            ? baseUrl.replace('/backend/api', '')
            : baseUrl.includes('/api')
            ? baseUrl.replace('/api', '')
            : baseUrl;

        return url;
    }

    setActiveDate(date: Date): void {
        this._date = getCurrentDate(date);
    }

    private setCanonicalUrl(url?: string): void {
        const link = (this.document.getElementById('canonical') ??
            this.document.createElement('link')) as HTMLLinkElement;

        link.setAttribute('rel', 'canonical');
        link.setAttribute('id', 'canonical');
        link.setAttribute('href', this.getCanonicalURL(url));

        if (!this.document.getElementById('canonical')) {
            this.document.head.appendChild(link);
        }
    }

    private getCanonicalURL(url?: string): string {
        return `${this.getBaseUrl()}${url ?? this.router.url}`;
    }

    private setMetaProperty(name: string, content: string): void {
        const id = `${name}`;
        const has = !!this.document.getElementById(id);

        const meta: MetaDefinition = {id, name, content};

        if (has) {
            this.meta.updateTag(meta);
        } else {
            this.meta.addTag(meta);
        }
    }
}
