import {Injectable} from '@angular/core';
import {
    ListResponse,
    MatchesTournaments,
    QueryParameters,
    Tournaments,
    TournamentsBySport,
} from '@nx-angular-standalone/shared/model';
import {Observable} from 'rxjs';

import {BaseCrudApi} from './base-crud.api';

@Injectable({providedIn: 'root'})
export class TournamentsApi extends BaseCrudApi<ListResponse<Tournaments>> {
    protected readonly suffix = 'web/tournaments';

    getTournaments(
        params: Partial<QueryParameters> = {},
    ): Observable<TournamentsBySport[]> {
        return this.http.get<TournamentsBySport[]>(
            `${this.environment.baseUrl}/${this.suffix}/by-sports`,
            {
                params,
                headers: this.headers,
            },
        );
    }

    getMatches(
        params: Partial<QueryParameters> = {},
        id: number,
    ): Observable<MatchesTournaments> {
        return this.http.get<MatchesTournaments>(
            `${this.environment.baseUrl}/${this.suffix}/${id}/matches/`,
            {
                params,
                headers: this.headers,
            },
        );
    }
}
