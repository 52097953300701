import {isPlatformBrowser} from '@angular/common';
import {effect, Inject, inject, Injectable, Injector, PLATFORM_ID} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {LOCAL_STORAGE} from '@ng-web-apis/common';
import {DialogAdvertisingComponent} from '@nx-angular-standalone/main/ui';
import {Banner, BannerModal} from '@nx-angular-standalone/shared/model';
import {COUNTRY_CODE, getBannerSize} from '@nx-angular-standalone/shared/util';
import {TuiBreakpointService, TuiDialogService} from '@taiga-ui/core';
import {PolymorpheusComponent} from '@tinkoff/ng-polymorpheus';
import {
    catchError,
    EMPTY,
    filter,
    Observable,
    of,
    startWith,
    Subject,
    switchMap,
    take,
    timer,
} from 'rxjs';

import {AdvertisingApi} from '../api/advertising.api';

const DELAY = 5000;

@Injectable()
export class AdvertisingService {
    private readonly _advertisingApi = inject(AdvertisingApi);
    private readonly _localStorage = inject(LOCAL_STORAGE);
    private readonly _dialogs = inject(TuiDialogService);
    private readonly _injector = inject(Injector);
    private readonly openAdvertisingDialog$ = new Subject<void>();
    private readonly _breakpoint$ = inject(TuiBreakpointService).pipe(
        filter(() => this.isBrowser),
        take(1),
    );

    private readonly delay = timer(DELAY);
    private readonly breakpoint = toSignal(
        this.openAdvertisingDialog$.pipe(switchMap(() => this._breakpoint$)),
        {
            initialValue: 'desktopLarge',
        },
    );

    private readonly _banner$ = new Subject<void>();

    private readonly openAdvertisingDialog = toSignal(
        this.openAdvertisingDialog$.pipe(
            filter(() => !!this._localStorage.getItem(COUNTRY_CODE) && this.isBrowser),
            switchMap(() => this.delay),
            switchMap(() =>
                this.getAdvertisingBannerModal().pipe(
                    filter(Boolean),
                    switchMap(banner =>
                        this._dialogs.open(
                            new PolymorpheusComponent(
                                DialogAdvertisingComponent,
                                this._injector,
                            ),
                            {
                                data: getBannerSize(this.breakpoint(), banner),
                                closeable: false,
                                dismissible: false,
                                size: 'auto',
                            },
                        ),
                    ),
                ),
            ),
        ),
    );

    isBrowser = false;

    constructor(@Inject(PLATFORM_ID) _platformId: string) {
        effect(() => {
            this.openAdvertisingDialog();
        });

        this.isBrowser = isPlatformBrowser(_platformId);
    }

    get _key(): string {
        return (this._localStorage.getItem(COUNTRY_CODE) as string) || 'RU';
    }

    getAdvertisingBannerModal(): Observable<BannerModal | null> {
        return this._advertisingApi
            .get({country: this._key})
            .pipe(catchError(() => EMPTY));
    }

    get getAdvertisingBannerTop(): Observable<Banner> {
        return this._banner$.asObservable().pipe(
            startWith(this._key),
            filter(key => key !== null),
            switchMap(() =>
                this._advertisingApi
                    .getTopBanner({country: this._key})
                    .pipe(catchError(() => of())),
            ),
        );
    }

    update(): void {
        this.openAdvertisingDialog$.next();
        this._banner$.next();
    }
}
