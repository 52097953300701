import {Injectable} from '@angular/core';
import {Banner, BannerModal, QueryParameters} from '@nx-angular-standalone/shared/model';
import {Observable} from 'rxjs';

import {BaseCrudApi} from './base-crud.api';

@Injectable({providedIn: 'root'})
export class AdvertisingApi extends BaseCrudApi<BannerModal> {
    protected readonly suffix = 'web/modal-banner';
    protected readonly suffixCenter = 'web/banners_center';
    protected readonly suffixTop = 'web/banners_top';
    protected readonly suffixLeft = 'web/banners_left';
    protected readonly suffixRight = 'web/banners_right';

    getCenterBanner(params: Partial<QueryParameters> = {}): Observable<Banner> {
        return this.http.get<Banner>(
            `${this.environment.baseUrl}/${this.suffixCenter}/`,
            {
                params,
                headers: this.headers,
            },
        );
    }

    getTopBanner(params: Partial<QueryParameters> = {}): Observable<Banner> {
        return this.http.get<Banner>(`${this.environment.baseUrl}/${this.suffixTop}/`, {
            params,
            headers: this.headers,
        });
    }

    getLeftBanner(params: Partial<QueryParameters> = {}): Observable<Banner> {
        return this.http.get<Banner>(`${this.environment.baseUrl}/${this.suffixLeft}/`, {
            params,
            headers: this.headers,
        });
    }

    getRightBanner(params: Partial<QueryParameters> = {}): Observable<Banner> {
        return this.http.get<Banner>(`${this.environment.baseUrl}/${this.suffixRight}/`, {
            params,
            headers: this.headers,
        });
    }
}
