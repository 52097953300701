import * as i0 from '@angular/core';
import { Directive, Inject, Input, HostBinding, NgModule } from '@angular/core';
import { TuiInteractiveState } from '@taiga-ui/core/enums';
import { MODE_PROVIDER } from '@taiga-ui/core/providers';
import { TUI_MODE } from '@taiga-ui/core/tokens';
import * as i1 from 'rxjs';
class TuiWrapperDirective {
  constructor(mode$) {
    this.mode$ = mode$;
    this.disabled = false;
    this.readOnly = false;
    this.hover = null;
    this.active = null;
    this.focus = false;
    this.invalid = false;
    this.appearance = '';
  }
  get computedInvalid() {
    return !this.disabled && !this.readOnly && this.invalid;
  }
  get computedFocused() {
    return this.focus && !this.disabled;
  }
  get interactiveState() {
    if (this.disabled) {
      return TuiInteractiveState.Disabled;
    }
    if (this.readOnly) {
      return TuiInteractiveState.Readonly;
    }
    if (this.active) {
      return TuiInteractiveState.Active;
    }
    if (this.hover) {
      return TuiInteractiveState.Hover;
    }
    return null;
  }
  get noHover() {
    return this.readOnly || this.hover === false;
  }
  get noActive() {
    return this.readOnly || this.active === false;
  }
}
TuiWrapperDirective.ɵfac = function TuiWrapperDirective_Factory(t) {
  return new (t || TuiWrapperDirective)(i0.ɵɵdirectiveInject(TUI_MODE));
};
TuiWrapperDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiWrapperDirective,
  selectors: [["", "tuiWrapper", ""]],
  hostVars: 10,
  hostBindings: function TuiWrapperDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("$.data-mode.attr", function TuiWrapperDirective___data_mode_attr_HostBindingHandler() {
        return ctx.mode$;
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("data-appearance", ctx.appearance)("data-state", ctx.interactiveState);
      i0.ɵɵclassProp("_invalid", ctx.computedInvalid)("_focused", ctx.computedFocused)("_no-hover", ctx.noHover)("_no-active", ctx.noActive);
    }
  },
  inputs: {
    disabled: "disabled",
    readOnly: "readOnly",
    hover: "hover",
    active: "active",
    focus: "focus",
    invalid: "invalid",
    appearance: "appearance"
  },
  features: [i0.ɵɵProvidersFeature([MODE_PROVIDER])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiWrapperDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiWrapper]',
      providers: [MODE_PROVIDER],
      host: {
        '($.data-mode.attr)': 'mode$'
      }
    }]
  }], function () {
    return [{
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_MODE]
      }]
    }];
  }, {
    disabled: [{
      type: Input
    }],
    readOnly: [{
      type: Input
    }],
    hover: [{
      type: Input
    }],
    active: [{
      type: Input
    }],
    focus: [{
      type: Input
    }],
    invalid: [{
      type: Input
    }],
    appearance: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.data-appearance']
    }],
    computedInvalid: [{
      type: HostBinding,
      args: ['class._invalid']
    }],
    computedFocused: [{
      type: HostBinding,
      args: ['class._focused']
    }],
    interactiveState: [{
      type: HostBinding,
      args: ['attr.data-state']
    }],
    noHover: [{
      type: HostBinding,
      args: ['class._no-hover']
    }],
    noActive: [{
      type: HostBinding,
      args: ['class._no-active']
    }]
  });
})();
class TuiWrapperModule {}
TuiWrapperModule.ɵfac = function TuiWrapperModule_Factory(t) {
  return new (t || TuiWrapperModule)();
};
TuiWrapperModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiWrapperModule
});
TuiWrapperModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiWrapperModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiWrapperDirective],
      exports: [TuiWrapperDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiWrapperDirective, TuiWrapperModule };
