import {ChangeDetectionStrategy, Component, HostBinding, Inject} from '@angular/core';
import {WINDOW} from '@ng-web-apis/common';
import {BannerModal} from '@nx-angular-standalone/shared/model';
import {TuiDialogContext} from '@taiga-ui/core';
import {POLYMORPHEUS_CONTEXT} from '@tinkoff/ng-polymorpheus';

import {DialogAdvertisingImports} from './dialog-advertising.imports';

@Component({
    selector: 'nx-angular-standalone-dialog-advertising',
    standalone: true,
    imports: DialogAdvertisingImports,
    templateUrl: './dialog-advertising.component.html',
    styleUrls: ['./dialog-advertising.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogAdvertisingComponent {
    constructor(
        @Inject(POLYMORPHEUS_CONTEXT)
        private readonly _context: TuiDialogContext<boolean, any>,
        @Inject(WINDOW) private readonly _window: Window,
    ) {}

    @HostBinding('attr.ngSkipHydration')
    get ngSkipHydration(): boolean {
        return true;
    }

    get banner(): BannerModal | null {
        return this._context?.data;
    }

    openLink(link: string): void {
        this._window.open(link, '_blank');
    }

    onClose(event: Event): void {
        event.stopPropagation();
        this._context.completeWith(false);
    }
}
