import {SportsEnum} from '@nx-angular-standalone/shared/model';

export const getKeySport = (sport: string): number => {
    if (!sport) {
        return SportsEnum.Football;
    }

    const iconsMap: Map<string, number> = new Map([
        ['Футбол', SportsEnum.Football],
        ['Баскетбол', SportsEnum.Basketball],
        ['Волейбол', SportsEnum.Volleyball],
        ['Теннис', SportsEnum.Tennis],
        ['Хоккей', SportsEnum.Hockey],
        ['Настольный теннис', SportsEnum.TableTennis],
        ['Киберспорт', SportsEnum.Cybersport],
        ['UFC', SportsEnum.UFC],
        ['football', SportsEnum.Football],
        ['basketball', SportsEnum.Basketball],
        ['volleyball', SportsEnum.Volleyball],
        ['tennis', SportsEnum.Tennis],
        ['hockey', SportsEnum.Hockey],
        ['table-tennis', SportsEnum.TableTennis],
        ['cybersport', SportsEnum.Cybersport],
        ['ufc', SportsEnum.UFC],
    ]);

    return iconsMap.get(sport) as number;
};
