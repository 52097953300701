import {ChangeDetectionStrategy, Component, HostBinding, Inject} from '@angular/core';
import {LOCAL_STORAGE} from '@ng-web-apis/common';
import {CURRENT_COUNTRY} from '@nx-angular-standalone/core/tokens';
import {
    AdvertisingService,
    CountriesService,
    NewsService,
} from '@nx-angular-standalone/shared/data-access';
import {Country} from '@nx-angular-standalone/shared/model';
import {COUNTRY_CODE} from '@nx-angular-standalone/shared/util';
import {TuiBreakpointService, TuiDialogContext} from '@taiga-ui/core';
import {POLYMORPHEUS_CONTEXT} from '@tinkoff/ng-polymorpheus';
import {BehaviorSubject, shareReplay} from 'rxjs';

import {DialogCountrySelectionImports} from './dialog-country-selection.imports';

@Component({
    selector: 'nx-angular-standalone-dialog-country-selection',
    standalone: true,
    imports: DialogCountrySelectionImports,
    templateUrl: './dialog-country-selection.component.html',
    styleUrls: ['./dialog-country-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogCountrySelectionComponent {
    currentCountry: Country | null = null;

    readonly isLoading$ = this._countriesService.loading$;

    readonly countries$ = this._countriesService
        .getCountries()
        .pipe(shareReplay({bufferSize: 1, refCount: true}));

    limit!: number;

    constructor(
        @Inject(POLYMORPHEUS_CONTEXT)
        private readonly _context: TuiDialogContext<boolean>,
        @Inject(LOCAL_STORAGE)
        private readonly _localStorage: Storage,
        @Inject(CURRENT_COUNTRY)
        private readonly _currentCountry: BehaviorSubject<Country>,
        @Inject(CountriesService) private readonly _countriesService: CountriesService,
        @Inject(AdvertisingService)
        private readonly _advertisingService: AdvertisingService,
        @Inject(NewsService)
        private readonly _newsService: NewsService,
        @Inject(TuiBreakpointService)
        private readonly _breakpoint: TuiBreakpointService,
    ) {
        this._breakpoint.subscribe(breakpoint => {
            breakpoint === 'desktopLarge' ? (this.limit = 5) : (this.limit = 2);
        });
    }

    @HostBinding('attr.ngSkipHydration')
    get ngSkipHydration(): boolean {
        return true;
    }

    onCurrentCountry(country: Country): void {
        this.currentCountry = country;
    }

    selectedCountry(): void {
        if (this.currentCountry === null) {
            return;
        }

        this._currentCountry.next(this.currentCountry);
        this._localStorage.setItem(COUNTRY_CODE, this.currentCountry.code);
        this._context.completeWith(false);
        this._advertisingService.update();
        this._newsService.queryParameters$.next({
            country: this.currentCountry.code,
            limit: this.limit,
        });

        this._context.completeWith(true);
    }
}
