import {environment} from '@nx-angular-standalone/shared/environments';

export const addGoogleAnalytic = (): void => {
    if (!environment.analytics.ga) {
        return;
    }

    const script1 = document.createElement('script');
    const script2 = document.createElement('script');

    script1.async = true;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${environment.analytics.ga}`;
    document.head.appendChild(script1);

    script2.innerHTML =
        ' window.dataLayer = window.dataLayer || [];\n' +
        '  function gtag(){dataLayer.push(arguments);}\n' +
        "  gtag('js', new Date());\n" +
        '\n' +
        `  gtag('config', '${environment.analytics.ga}');`;
    document.head.appendChild(script2);
};
