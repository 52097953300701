export const VIEW_SPORTS_MAP: {[key: string]: string} = {
    футбол: 'Посмотреть все футбольные события',
    баскетбол: 'Посмотреть все баскетбольные события',
    волейбол: 'Посмотреть все волейбольные события',
    теннис: 'Посмотреть все теннисные события',
    хоккей: 'Посмотреть все хоккейные события',
    'настольный теннис': 'Посмотреть все события настольного тенниса',
    киберспорт: 'Посмотреть все события киберспорта',
    ufc: 'Посмотреть все события UFC',
};
