// TODO: change type in v4.0
// eslint-disable-next-line no-restricted-syntax
var TuiDayOfWeek;
(function (TuiDayOfWeek) {
    TuiDayOfWeek[TuiDayOfWeek["Sunday"] = 0] = "Sunday";
    TuiDayOfWeek[TuiDayOfWeek["Monday"] = 1] = "Monday";
    TuiDayOfWeek[TuiDayOfWeek["Tuesday"] = 2] = "Tuesday";
    TuiDayOfWeek[TuiDayOfWeek["Wednesday"] = 3] = "Wednesday";
    TuiDayOfWeek[TuiDayOfWeek["Thursday"] = 4] = "Thursday";
    TuiDayOfWeek[TuiDayOfWeek["Friday"] = 5] = "Friday";
    TuiDayOfWeek[TuiDayOfWeek["Saturday"] = 6] = "Saturday";
})(TuiDayOfWeek || (TuiDayOfWeek = {}));

// TODO: change type in v4.0
// eslint-disable-next-line no-restricted-syntax
var TuiMonthNumber;
(function (TuiMonthNumber) {
    TuiMonthNumber[TuiMonthNumber["January"] = 0] = "January";
    TuiMonthNumber[TuiMonthNumber["February"] = 1] = "February";
    TuiMonthNumber[TuiMonthNumber["March"] = 2] = "March";
    TuiMonthNumber[TuiMonthNumber["April"] = 3] = "April";
    TuiMonthNumber[TuiMonthNumber["May"] = 4] = "May";
    TuiMonthNumber[TuiMonthNumber["June"] = 5] = "June";
    TuiMonthNumber[TuiMonthNumber["July"] = 6] = "July";
    TuiMonthNumber[TuiMonthNumber["August"] = 7] = "August";
    TuiMonthNumber[TuiMonthNumber["September"] = 8] = "September";
    TuiMonthNumber[TuiMonthNumber["October"] = 9] = "October";
    TuiMonthNumber[TuiMonthNumber["November"] = 10] = "November";
    TuiMonthNumber[TuiMonthNumber["December"] = 11] = "December";
})(TuiMonthNumber || (TuiMonthNumber = {}));

/**
 * Generated bundle index. Do not edit.
 */

export { TuiDayOfWeek, TuiMonthNumber };

