import {SportsEnum} from '@nx-angular-standalone/shared/model';

export const getTextSport = (sport: SportsEnum): string => {
    const iconsMap: Map<SportsEnum, string> = new Map([
        [SportsEnum.Football, 'Футбол'],
        [SportsEnum.Basketball, 'Баскетбол'],
        [SportsEnum.Volleyball, 'Волейбол'],
        [SportsEnum.Tennis, 'Теннис'],
        [SportsEnum.Hockey, 'Хоккей'],
        [SportsEnum.TableTennis, 'Настольный теннис'],
        [SportsEnum.Cybersport, 'Киберспорт'],
        [SportsEnum.UFC, 'UFC'],
    ]);

    return iconsMap.get(sport) ?? '';
};

export const getMetaSport = (sport: SportsEnum): {title: string; description: string} => {
    const metaMap: Map<SportsEnum, {title: string; description: string}> = new Map([
        [
            SportsEnum.Football,
            {
                title: 'Футбольный Матч-Центр infoBet.bet - Все о Футбольных Играх и Ставках',
                description:
                    'Откройте для себя мир футбола на infoBet.bet: все о футбольных матчах, включая УЕФА, Премьер-лигу, Серию А, Ла Лигу. Получите актуальную аналитику, новости и лучшие предложения для ставок. Не пропустите ни одного гола или поворотного момента в футбольном мире!',
            },
        ],
        [
            SportsEnum.Basketball,
            {
                title: 'infoBet.bet - Ваш Баскетбольный Матч-Центр: Новости, Аналитика, Ставки',
                description:
                    'Присоединяйтесь к матч-центру баскетбола на infoBet.bet и получите доступ к информации о международных турнирах, NBA и других лигах. Следите за играми и делайте обоснованные ставки.',
            },
        ],
        [
            SportsEnum.Volleyball,
            {
                title: 'infoBet.bet - Матч-Центр Волейбола: Следите за Играми и Делайте Ставки',
                description:
                    'Присоединяйтесь к infoBet.bet для просмотра волейбольных матчей, аналитики и ставок. Получите доступ к информации о международных турнирах и национальных чемпионатах, расписаниях игр и результатах.',
            },
        ],
        [
            SportsEnum.Tennis,
            {
                title: 'infoBet.bet - Все о Теннисных Турнирах: Следите и Делайте Ставки',
                description:
                    'Исследуйте мир тенниса с infoBet.bet. Узнайте о турнирах Grand Slam, ATP и WTA, получите полную аналитику и совершайте ставки на теннисные матчи.',
            },
        ],
        [
            SportsEnum.Hockey,
            {
                title: 'infoBet.bet - Хоккейный Матч-Центр: Следите за Лучшими Играми и Делайте Ставки',
                description:
                    'Оставайтесь на связи с хоккейным миром через матч-центр infoBet.bet. Получите доступ к расписанию НХЛ, мировых чемпионатов и других важных турниров. Аналитика и ставки на хоккей теперь в одном месте.',
            },
        ],
        [
            SportsEnum.TableTennis,
            {
                title: 'infoBet.bet - Настольный Теннис: Следите за Турнирами и Делайте Ставки',
                description:
                    'Исследуйте мир настольного тенниса с infoBet.bet. Узнайте о предстоящих турнирах, получите аналитические данные и совершайте ставки на настольный теннис.',
            },
        ],
        [
            SportsEnum.Cybersport,
            {
                title: 'infoBet.bet - Киберспорт: Следите за Главными Событиями и Делайте Ставки',
                description:
                    'Погрузитесь в мир киберспорта с infoBet.bet. Получите доступ к информации о турнирах по CS:GO, LoL и другим играм, а также к аналитике и ставкам.',
            },
        ],
        [
            SportsEnum.UFC,
            {
                title: 'infoBet.bet - Все о UFC: Следите за Боями и Делайте Ставки',
                description:
                    'Исследуйте мир UFC на infoBet.bet. Получите информацию о предстоящих боях, аналитические данные и возможности для ставок на смешанные единоборства.',
            },
        ],
    ]);

    return metaMap.get(sport) ?? {title: '', description: ''};
};

export const getDeclensionOfSport = (sport: SportsEnum): string => {
    const textMap: Map<SportsEnum, string> = new Map([
        [SportsEnum.Football, 'футбольных'],
        [SportsEnum.Basketball, 'баскетбольных'],
        [SportsEnum.Volleyball, 'волейбольных'],
        [SportsEnum.Tennis, 'теннисных'],
        [SportsEnum.Hockey, 'хоккейных'],
        [SportsEnum.TableTennis, 'настольный теннис'],
        [SportsEnum.Cybersport, 'киберспортивных'],
        [SportsEnum.UFC, 'ufc'],
    ]);

    return textMap.get(sport) ?? '';
};
