import * as i0 from '@angular/core';
import { ViewContainerRef, TemplateRef, Directive, Inject, Input, NgModule } from '@angular/core';

/**
 * @internal
 */
class TuiLetContext {
  constructor(internalDirectiveInstance) {
    this.internalDirectiveInstance = internalDirectiveInstance;
  }
  get $implicit() {
    return this.internalDirectiveInstance.tuiLet;
  }
  get tuiLet() {
    return this.internalDirectiveInstance.tuiLet;
  }
}

/**
 * Works like *ngIf but does not have a condition — use it to declare
 * the result of pipes calculation (i.e. async pipe)
 */
class TuiLetDirective {
  constructor(viewContainer, templateRef) {
    viewContainer.createEmbeddedView(templateRef, new TuiLetContext(this));
  }
  /**
   * Asserts the correct type of the context for the template that `TuiLet` will render.
   *
   * The presence of this method is a signal to the Ivy template type-check compiler that the
   * `TuiLet` structural directive renders its template with a specific context type.
   */
  static ngTemplateContextGuard(_dir, _ctx) {
    return true;
  }
}
TuiLetDirective.ɵfac = function TuiLetDirective_Factory(t) {
  return new (t || TuiLetDirective)(i0.ɵɵdirectiveInject(ViewContainerRef), i0.ɵɵdirectiveInject(TemplateRef));
};
TuiLetDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiLetDirective,
  selectors: [["", "tuiLet", ""]],
  inputs: {
    tuiLet: "tuiLet"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiLetDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiLet]'
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef,
      decorators: [{
        type: Inject,
        args: [ViewContainerRef]
      }]
    }, {
      type: i0.TemplateRef,
      decorators: [{
        type: Inject,
        args: [TemplateRef]
      }]
    }];
  }, {
    tuiLet: [{
      type: Input
    }]
  });
})();
class TuiLetModule {}
TuiLetModule.ɵfac = function TuiLetModule_Factory(t) {
  return new (t || TuiLetModule)();
};
TuiLetModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiLetModule
});
TuiLetModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiLetModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiLetDirective],
      exports: [TuiLetDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiLetContext, TuiLetDirective, TuiLetModule };
