export const sportByUrl = (url: string): string => {
    const urlsMap = new Map([
        ['football', 'Футбол'],
        ['basketball', 'Баскетбол'],
        ['volleyball', 'Волейбол'],
        ['tennis', 'Теннис'],
        ['hockey', 'Хоккей'],
        ['table-tennis', 'Настольный теннис'],
        ['cybersport', 'Киберспорт'],
        ['ufc', 'UFC'],
    ]);

    return urlsMap.get(url) ?? '';
};
