import { svgNodeFilter, CHAR_NO_BREAK_SPACE, CHAR_ZERO_WIDTH_SPACE } from '@taiga-ui/cdk';

function tuiCheckFixedPosition(element) {
    if (!element || typeof getComputedStyle === `undefined`) {
        return false;
    }
    const style = getComputedStyle(element);
    return (style.getPropertyValue(`position`) === `fixed` ||
        tuiCheckFixedPosition(element.parentElement));
}

/**
 * Calculates size of safe area on mobile devices (status bars)
 *
 * ATTENTION: Only use in browser environment
 */
function tuiGetSafeAreaSize() {
    if (!CSS.supports(`padding-top: env(safe-area-inset-top)`)) {
        return 0;
    }
    const div = document.createElement(`div`);
    div.style.paddingTop = `env(safe-area-inset-top)`;
    document.body.appendChild(div);
    const safeAreaSize = parseInt(window.getComputedStyle(div).paddingTop, 10) || 0;
    document.body.removeChild(div);
    return safeAreaSize;
}

function tuiGetScreenWidth(doc) {
    return Math.max(doc.documentElement.clientWidth, doc.defaultView ? doc.defaultView.innerWidth : 0);
}

/**
 * @description:
 * Cross-browser @media (height)
 *
 * 1. window.innerWidth
 * 1.1. gets CSS viewport @media (height) which include scrollbars
 * 1.2. initial-scale and zoom variations may cause mobile values to
 *      wrongly scale down to what PPK calls the visual
 *      viewport and be smaller than the @media values
 *  1.3. zoom may cause values to be 1px off due to native rounding
 *
 *  2. document.documentElement.clientHeight
 *  2.1. equals CSS viewport width minus scrollbar width
 *  2.2. matches @media (height) when there is no scrollbar
 *  2.3. available cross-browser
 *  2.4. inaccurate if doctype is missing
 */
function tuiGetViewportHeight({ document, innerHeight }) {
    return Math.max(document.documentElement.clientHeight || 0, innerHeight || 0);
}

/**
 * @description:
 * Cross-browser @media (width)
 *
 * 1. window.innerWidth
 * 1.1. gets CSS viewport @media (width) which include scrollbars
 * 1.2. initial-scale and zoom variations may cause mobile values to
 *      wrongly scale down to what PPK calls the visual
 *      viewport and be smaller than the @media values
 *  1.3. zoom may cause values to be 1px off due to native rounding
 *
 *  2. document.documentElement.clientWidth
 *  2.1. equals CSS viewport width minus scrollbar width
 *  2.2. matches @media (width) when there is no scrollbar
 *  2.3. available cross-browser
 *  2.4. inaccurate if doctype is missing
 */
function tuiGetViewportWidth({ document, innerWidth }) {
    return Math.max(document.documentElement.clientWidth || 0, innerWidth || 0);
}

/**
 * Creates a cloned range with its boundaries set at word boundaries
 *
 * @param currentRange a range to clone
 * @return modified range
 */
// eslint-disable-next-line max-statements
function tuiGetWordRange(currentRange) {
    const range = currentRange.cloneRange();
    const { startContainer, startOffset, endContainer, endOffset } = range;
    const { ownerDocument } = startContainer;
    if (!ownerDocument) {
        return range;
    }
    const treeWalker = ownerDocument.createTreeWalker(ownerDocument.body, NodeFilter.SHOW_TEXT, svgNodeFilter);
    treeWalker.currentNode = startContainer;
    do {
        const container = treeWalker.currentNode;
        const textContent = container.textContent || ``;
        const content = container === startContainer
            ? textContent.slice(0, Math.max(0, startOffset + 1))
            : textContent;
        const offset = Math.max(content.lastIndexOf(` `), content.lastIndexOf(CHAR_NO_BREAK_SPACE), content.lastIndexOf(CHAR_ZERO_WIDTH_SPACE)) + 1;
        range.setStart(container, 0);
        if (offset) {
            range.setStart(container, offset);
            break;
        }
    } while (treeWalker.previousNode());
    treeWalker.currentNode = endContainer;
    do {
        const container = treeWalker.currentNode;
        const textContent = container.textContent || ``;
        const content = container === endContainer ? textContent.slice(endOffset + 1) : textContent;
        const offset = [
            content.indexOf(` `),
            content.indexOf(CHAR_NO_BREAK_SPACE),
            content.indexOf(CHAR_ZERO_WIDTH_SPACE),
        ].reduce((result, item) => result === -1 || item === -1
            ? Math.max(result, item)
            : Math.min(result, item), -1);
        range.setEnd(container, textContent.length);
        if (offset !== -1) {
            range.setEnd(container, offset + textContent.length - content.length);
            break;
        }
    } while (treeWalker.nextNode());
    return range;
}

const WIDTH_SEARCH = `width="`;
const HEIGHT_SEARCH = `height="`;
const START = `<svg`;
function tuiProcessIcon(source, name) {
    if (source.includes(`id="${name}"`)) {
        return source;
    }
    const src = source.slice(Math.max(0, source.indexOf(START)));
    const attributes = src.slice(0, Math.max(0, src.indexOf(`>`)));
    if (!(attributes === null || attributes === void 0 ? void 0 : attributes.includes(WIDTH_SEARCH)) || !attributes.includes(HEIGHT_SEARCH)) {
        return `${src.replace(START, `<svg xmlns="http://www.w3.org/2000/svg"><g id="${name}" xmlns="http://www.w3.org/2000/svg"><svg`)}</g></svg>`;
    }
    const indexOfWidth = attributes.indexOf(WIDTH_SEARCH);
    const indexOfHeight = attributes.indexOf(HEIGHT_SEARCH);
    const widthOffset = indexOfWidth + WIDTH_SEARCH.length;
    const heightOffset = indexOfHeight + HEIGHT_SEARCH.length;
    const widthString = attributes.slice(widthOffset, attributes.indexOf(`"`, widthOffset));
    const heightString = attributes.slice(heightOffset, attributes.indexOf(`"`, heightOffset));
    if (!heightString ||
        !widthString ||
        widthString.includes(`%`) ||
        heightString.includes(`%`) ||
        widthString.includes(`em`) ||
        heightString.includes(`em`)) {
        return src.replace(START, `<svg id="${name}"`);
    }
    const width = parseInt(widthString, 10);
    const height = parseInt(heightString, 10);
    return `<g id="${name}" xmlns="http://www.w3.org/2000/svg"><svg  x="50%" y="50%" width="${width / 16}em" height="${height / 16}em" overflow="visible" viewBox="0 0 ${width} ${height}"><svg x="${-width / 2}" y="${-height / 2}">${src}</svg></svg></g>`;
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiCheckFixedPosition, tuiGetSafeAreaSize, tuiGetScreenWidth, tuiGetViewportHeight, tuiGetViewportWidth, tuiGetWordRange, tuiProcessIcon };

