import {Injectable} from '@angular/core';
import {
    Bookmaker,
    BookmakersMain,
    QueryParameters,
} from '@nx-angular-standalone/shared/model';
import {removeEmptyValues} from '@nx-angular-standalone/shared/util';
import {Observable} from 'rxjs';

import {BaseCrudApi} from './base-crud.api';

@Injectable({providedIn: 'root'})
export class BookmakersApi extends BaseCrudApi<Bookmaker[]> {
    protected readonly suffix = 'web/bookmakers';

    getBookmakers(params: Partial<QueryParameters> = {}): Observable<BookmakersMain> {
        return this.http.get<BookmakersMain>(
            `${this.environment.baseUrl}/${this.suffix}/main`,
            {
                params: removeEmptyValues(params),
                headers: this.headers,
            },
        );
    }
}
