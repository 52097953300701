export const iconBySport = (sport: string): string => {
    if (!sport) {
        return '';
    }

    const iconsMap: Map<string, string> = new Map([
        ['Футбол', 'football'],
        ['Баскетбол', 'basketball'],
        ['Волейбол', 'volleyball'],
        ['Теннис', 'tennis'],
        ['Хоккей', 'hockey'],
        ['Настольный теннис', 'table-tennis'],
        ['Киберспорт', 'cybersport'],
        ['UFC', 'ufc'],
    ]);

    return iconsMap.get(sport) as string;
};

export const iconBySportName = (sport: string | undefined): string =>
    `client::${iconBySport(sport ?? '')}`;
