import * as i0 from '@angular/core';
import { InjectionToken, Injector, Directive, Self, TemplateRef, ChangeDetectorRef, ComponentFactoryResolver, NgModule } from '@angular/core';

/**
 * Use this token to access context within your components when
 * instantiating them through {@link PolymorpheusOutletDirective}
 */
const POLYMORPHEUS_CONTEXT = new InjectionToken('POLYMORPHEUS_CONTEXT');

/**
 * Wrapper class for a component that will be used as content for {@link PolymorpheusOutletDirective}
 *
 * @param component — an Angular component to be dynamically created
 * @param injector — optional {@link Injector} for lazy loaded module case
 *
 * TODO: Remove second generic as it is irrelevant, remove `null` from injector type
 */
class PolymorpheusComponent {
  constructor(component, i) {
    this.component = component;
    this.i = i;
  }
  createInjector(injector, useValue) {
    return Injector.create({
      parent: this.i || injector,
      providers: [{
        provide: POLYMORPHEUS_CONTEXT,
        useValue
      }]
    });
  }
}

/**
 * ng-template wrapper directive also stores {@link ChangeDetectorRef} to properly handle change detection.
 */
class PolymorpheusTemplate {
  constructor(template, cdr) {
    this.template = template;
    this.cdr = cdr;
    this.polymorpheus = '';
  }
  check() {
    this.cdr.markForCheck();
  }
  static ngTemplateContextGuard(_dir, _ctx) {
    return true;
  }
}
/** @nocollapse */
PolymorpheusTemplate.ɵfac = function PolymorpheusTemplate_Factory(t) {
  return new (t || PolymorpheusTemplate)(i0.ɵɵdirectiveInject(i0.TemplateRef, 2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
/** @nocollapse */
PolymorpheusTemplate.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PolymorpheusTemplate,
  selectors: [["ng-template", "polymorpheus", ""]],
  inputs: {
    polymorpheus: "polymorpheus"
  },
  exportAs: ["polymorpheus"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PolymorpheusTemplate, [{
    type: Directive,
    args: [{
      selector: 'ng-template[polymorpheus]',
      exportAs: 'polymorpheus',
      inputs: ['polymorpheus']
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Self
      }]
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();
class PolymorpheusContext {
  constructor($implicit) {
    this.$implicit = $implicit;
  }
  get polymorpheusOutlet() {
    return this.$implicit;
  }
}
/**
 * @deprecated: use {@link PolymorpheusContext} instead
 * Primitive types used as content by {@link PolymorpheusOutletDirective}
 */
class PrimitiveContext extends PolymorpheusContext {}
class PolymorpheusOutletDirective {
  constructor(vcr, i, t) {
    this.vcr = vcr;
    this.i = i;
    this.t = t;
    this.content = '';
  }
  get template() {
    if (isDirective(this.content)) {
      return this.content.template;
    }
    return this.content instanceof TemplateRef ? this.content : this.t;
  }
  ngOnChanges({
    content
  }) {
    var _a;
    const context = this.getContext();
    if (this.v) {
      this.v.context = context;
    }
    (_a = this.c) === null || _a === void 0 ? void 0 : _a.injector.get(ChangeDetectorRef).markForCheck();
    if (!content) {
      return;
    }
    this.vcr.clear();
    if (isComponent(this.content)) {
      this.process(this.content);
    } else if (
    // tslint:disable-next-line:triple-equals
    (context instanceof PolymorpheusContext && context.$implicit) != null) {
      this.v = this.vcr.createEmbeddedView(this.template, context);
    }
  }
  ngDoCheck() {
    if (isDirective(this.content)) {
      this.content.check();
    }
  }
  static ngTemplateContextGuard(_dir, _ctx) {
    return true;
  }
  getContext() {
    if (isTemplate(this.content) || isComponent(this.content)) {
      return this.context;
    }
    return new PolymorpheusContext(typeof this.content === 'function' ? this.content(this.context) : this.content);
  }
  process(content) {
    const injector = content.createInjector(this.i, this.context && new Proxy(this.context, {
      get: (_, key) => {
        var _a;
        return (_a = this.context) === null || _a === void 0 ? void 0 : _a[key];
      }
    }));
    this.c = this.vcr.createComponent(injector.get(ComponentFactoryResolver).resolveComponentFactory(content.component), 0, injector);
  }
}
/** @nocollapse */
PolymorpheusOutletDirective.ɵfac = function PolymorpheusOutletDirective_Factory(t) {
  return new (t || PolymorpheusOutletDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.TemplateRef));
};
/** @nocollapse */
PolymorpheusOutletDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PolymorpheusOutletDirective,
  selectors: [["", "polymorpheusOutlet", ""]],
  inputs: {
    content: [i0.ɵɵInputFlags.None, "polymorpheusOutlet", "content"],
    context: [i0.ɵɵInputFlags.None, "polymorpheusOutletContext", "context"]
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PolymorpheusOutletDirective, [{
    type: Directive,
    args: [{
      selector: '[polymorpheusOutlet]',
      inputs: ['content: polymorpheusOutlet', 'context: polymorpheusOutletContext']
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.TemplateRef
    }];
  }, null);
})();
function isDirective(content) {
  return content instanceof PolymorpheusTemplate;
}
function isComponent(content) {
  return content instanceof PolymorpheusComponent;
}
function isTemplate(content) {
  return isDirective(content) || content instanceof TemplateRef;
}
class PolymorpheusModule {}
/** @nocollapse */
PolymorpheusModule.ɵfac = function PolymorpheusModule_Factory(t) {
  return new (t || PolymorpheusModule)();
};
/** @nocollapse */
PolymorpheusModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: PolymorpheusModule
});
/** @nocollapse */
PolymorpheusModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PolymorpheusModule, [{
    type: NgModule,
    args: [{
      declarations: [PolymorpheusOutletDirective, PolymorpheusTemplate],
      exports: [PolymorpheusOutletDirective, PolymorpheusTemplate]
    }]
  }], null, null);
})();

/**
 * Public API Surface of ng-polymorpheus
 */

/**
 * Generated bundle index. Do not edit.
 */

export { POLYMORPHEUS_CONTEXT, PolymorpheusComponent, PolymorpheusModule, PolymorpheusOutletDirective, PolymorpheusTemplate };
