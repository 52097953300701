import * as i0 from '@angular/core';
import { Directive, Input, NgModule } from '@angular/core';
import { AbstractTuiController } from '@taiga-ui/cdk';
class TuiModeDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    this.mode = null;
  }
}
TuiModeDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiModeDirective_BaseFactory;
  return function TuiModeDirective_Factory(t) {
    return (ɵTuiModeDirective_BaseFactory || (ɵTuiModeDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiModeDirective)))(t || TuiModeDirective);
  };
})();
TuiModeDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiModeDirective,
  selectors: [["", "tuiMode", ""]],
  inputs: {
    mode: [i0.ɵɵInputFlags.None, "tuiMode", "mode"]
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiModeDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiMode]'
    }]
  }], null, {
    mode: [{
      type: Input,
      args: ['tuiMode']
    }]
  });
})();
class TuiModeModule {}
TuiModeModule.ɵfac = function TuiModeModule_Factory(t) {
  return new (t || TuiModeModule)();
};
TuiModeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiModeModule
});
TuiModeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiModeModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiModeDirective],
      exports: [TuiModeDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiModeDirective, TuiModeModule };
