import { EMPTY_FUNCTION } from '@taiga-ui/cdk/constants';

const tuiAssert = {
    /**
     * @deprecated:
     * The current field no longer matters as
     * it will not be provided in production
     */
    enabled: false,
    get assert() {
        return ngDevMode
            ? Function.prototype.bind.call(console.assert, console)
            : EMPTY_FUNCTION;
    },
};

class TuiValidationError {
    constructor(message, context = {}) {
        this.message = message;
        this.context = context;
    }
}

/**
 * Generated bundle index. Do not edit.
 */

export { TuiValidationError, tuiAssert };

