import {inject, Injectable} from '@angular/core';
import {WINDOW} from '@ng-web-apis/common';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ScrollService {
    private readonly window = inject(WINDOW);

    readonly showScroll = new BehaviorSubject<boolean>(false);
    readonly downScroll = new BehaviorSubject<boolean>(false);
    readonly currentScroll = new BehaviorSubject<number>(0);
    readonly maxScroll = new BehaviorSubject<number>(0);

    scrollTo(scroll: number): void {
        this.showScroll.next(true);

        if (scroll > 0) {
            this.downScroll.next(true);
            this.window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else {
            this.downScroll.next(false);
            this.window.scrollTo({
                top: this.maxScroll.value,
                behavior: 'smooth',
            });
        }
    }
}
