import {Environment} from './environment.interface';

export const baseEnvironment: Environment = {
    production: false,
    baseUrl: 'https://sports.web.dev.thelightech.com/api',
    xAccessToken:
        'VdaVHnZFElwe9dxRc9mSs3X7HK2iYUaU8sQoUh1vjwAcIE1F79PemH2iq81EDm_NZT6jjmIbFKPCoDfef5sqRA',
    authorization:
        'Basic bGtuamRob2VyZG5qazpsb2Rqd2luaDRmMjkwZUlRS0NBc2xjZHdvaWhlZjkyM3UwOGgzZmV3ZGprcWx3aW9xaDIzOTMwaXdkaDlxcDNoMjRnODl1cGlv',
    analytics: {
        ga: 'G-6Q7G99SJM9',
        ym: '95509603',
    },
    siteName: 'InfoBet',
};
