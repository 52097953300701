import {
    LabelValuePair,
    MatchesCounter,
    MatchStatus,
} from '@nx-angular-standalone/shared/model';

export function getMatchStatusName(status: MatchStatus): string {
    const statuses = new Map([
        [MatchStatus.All, 'Все'],
        [MatchStatus.Live, 'Live'],
        [MatchStatus.Planned, 'Предстоящие матчи'],
        [MatchStatus.Finished, 'Завершенные'],
    ]);

    return statuses.get(status) ?? 'Все';
}

export function getCountMatch(count: number): string {
    if (!count) {
        return '';
    }

    return count > 999 ? '999+' : String(count);
}

export function statusNameWithCounter(status: MatchStatus, count: number): string {
    return `${getMatchStatusName(status)} ${getCountMatch(count)}`;
}

export function getMatchesWithCount({
    live,
    planned,
    finished,
}: MatchesCounter): Array<LabelValuePair<MatchStatus | undefined>> {
    return [
        {label: getMatchStatusName(MatchStatus.All), value: undefined},
        {
            label: statusNameWithCounter(MatchStatus.Live, live),
            value: MatchStatus.Live,
        },
        {
            label: statusNameWithCounter(MatchStatus.Planned, planned),
            value: MatchStatus.Planned,
        },
        {
            label: statusNameWithCounter(MatchStatus.Finished, finished),
            value: MatchStatus.Finished,
        },
    ];
}
