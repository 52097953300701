import {Injectable} from '@angular/core';
import {
    ListResponse,
    LiveTournamentsBySport,
    MatchById,
    Matches,
    MatchesByTournament,
    MatchesCounter,
    QueryParameters,
    TournamentBySport,
    TournamentBySports,
} from '@nx-angular-standalone/shared/model';
import {removeEmptyValues} from '@nx-angular-standalone/shared/util';
import {Observable} from 'rxjs';

import {BaseCrudApi} from './base-crud.api';

@Injectable({providedIn: 'root'})
export class MatchesApi extends BaseCrudApi<ListResponse<Matches>> {
    protected readonly suffix = 'web/matches';

    getMatchesCounter(params: Partial<QueryParameters> = {}): Observable<MatchesCounter> {
        return this.http.get<MatchesCounter>(
            `${this.environment.baseUrl}/${this.suffix}/counters`,
            {
                params: removeEmptyValues(params),
                headers: this.headers,
            },
        );
    }

    getTournamentCounter(
        params: Partial<QueryParameters> = {},
    ): Observable<MatchesCounter> {
        return this.http.get<MatchesCounter>(
            `${this.environment.baseUrl}/${this.suffix}/by_tournament_counters`,
            {
                params: removeEmptyValues(params),
                headers: this.headers,
            },
        );
    }

    getLiveMatches(
        params: Partial<QueryParameters> = {},
    ): Observable<LiveTournamentsBySport[]> {
        return this.http.get<LiveTournamentsBySport[]>(
            `${this.environment.baseUrl}/${this.suffix}/match_by_tournaments`,
            {
                params: removeEmptyValues(params),
                headers: this.headers,
            },
        );
    }

    getTournamentsBySports(
        params: Partial<QueryParameters> = {},
    ): Observable<TournamentBySports> {
        return this.http.get<TournamentBySports>(
            `${this.environment.baseUrl}/${this.suffix}/by_tournaments_by_sports`,
            {
                params: removeEmptyValues(params),
                headers: this.headers,
            },
        );
    }

    getTournamentsBySport(
        params: Partial<QueryParameters> = {},
    ): Observable<TournamentBySport> {
        return this.http.get<TournamentBySport>(
            `${this.environment.baseUrl}/${this.suffix}/by_tournaments_by_sport`,
            {
                params: removeEmptyValues(params),
                headers: this.headers,
            },
        );
    }

    getMatchesByTournament(
        params: Partial<QueryParameters> = {},
    ): Observable<MatchesByTournament> {
        return this.http.get<MatchesByTournament>(
            `${this.environment.baseUrl}/${this.suffix}/by_tournament`,
            {
                params: removeEmptyValues(params),
                headers: this.headers,
            },
        );
    }

    getMatchDetailById(
        matchId: number,
        params: Partial<QueryParameters> = {},
    ): Observable<MatchById> {
        return this.http.get<MatchById>(
            `${this.environment.baseUrl}/${this.suffix}/detail/${matchId}/`,
            {
                params: removeEmptyValues(params),
                headers: this.headers,
            },
        );
    }
}
