export function formatTimestamp(timestamp: string): string {
    const dateObject = new Date(timestamp);

    const getTwoDigitString = (value: number): string =>
        value.toString().padStart(2, '0');

    const year = dateObject.getFullYear();
    const month = getTwoDigitString(dateObject.getMonth() + 1); // Months are zero-based
    const day = getTwoDigitString(dateObject.getDate());
    const hours = getTwoDigitString(dateObject.getUTCHours());
    const minutes = getTwoDigitString(dateObject.getUTCMinutes());

    return `${year}-${day}-${month} ${hours}:${minutes}`;
}

export function formatMatchDate(timestamp: string): string {
    const dateObject = new Date(timestamp);
    const year = dateObject.getFullYear();

    return year.toString();
}
