<!--noindex-->
<div
    *ngIf="banner"
    class="advertising"
    [ngStyle]="{backgroundImage: 'url(' + banner.image + ')', backgroundSize: '100% 100%'}"
    (click)="openLink(banner.url_web)"
>
    <button
        appearance=""
        icon="client::close"
        tuiIconButton
        type="button"
        size="s"
        class="btn"
        (click)="onClose($event)"
    ></button>
</div>
<!--/noindex-->
