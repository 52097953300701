import {Inject, Injectable, signal} from '@angular/core';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {Router} from '@angular/router';
import {ComponentStore, tapResponse} from '@ngrx/component-store';
import {CURRENT_COUNTRY} from '@nx-angular-standalone/core/tokens';
import {
    Country,
    MatchesCounter,
    MatchStatus,
    QueryParameters,
    Sport,
    SportsEnum,
    TournamentBySport,
    TournamentBySports,
} from '@nx-angular-standalone/shared/model';
import {
    COUNTRY_CODE_FALLBACK,
    getCurrentDate,
    getKeySport,
} from '@nx-angular-standalone/shared/util';
import {BehaviorSubject, catchError, filter, map, of, skip, switchMap, tap} from 'rxjs';

import {MatchesApi} from '../api/matches.api';
import {SportsApi} from '../api/sports.api';
import {MetaService} from '../services/meta.service';

interface MatchCenterState {
    activeSport: number | undefined;
    activeSportName: string;
    activeStatus: MatchStatus | undefined;
    sports: Sport[];
    tournamentsBySports: TournamentBySports | null;
    tournamentsBySportsLoading: boolean;
    calendarActiveDate: Date;
    matchesCounter: MatchesCounter | null;
    matchesCounterLoading: boolean;
    tournamentsBySport: TournamentBySport | null;
    tournamentsBySportLoading: boolean;
}

const initialState: () => MatchCenterState = () => ({
    activeSport: undefined,
    activeSportName: '',
    activeStatus: undefined,
    sports: [],
    tournamentsBySports: null,
    tournamentsBySportsLoading: false,
    calendarActiveDate: new Date(),
    matchesCounter: null,
    matchesCounterLoading: false,
    tournamentsBySport: null,
    tournamentsBySportLoading: false,
});

@Injectable()
export class MatchCenterStore extends ComponentStore<MatchCenterState> {
    private readonly queryParams = signal<Partial<QueryParameters>>({
        date: getCurrentDate(),
        sport: undefined,
        status: undefined,
        country: this.countryCodeFallback,
    });

    private readonly fetchSports = this.effect<void>($ =>
        $.pipe(
            switchMap(() =>
                this.sportsApi.get().pipe(
                    catchError(() => of()),
                    map(sports => sports.sort((a, b) => (a.id > b.id ? 1 : -1))),
                    tap(sports => this.patchState({sports})),
                ),
            ),
        ),
    );

    readonly fetchMatchesCounter = this.effect<Partial<QueryParameters>>(queryParams$ =>
        queryParams$.pipe(
            tap(() =>
                this.patchState({matchesCounterLoading: true, matchesCounter: null}),
            ),
            switchMap(query =>
                this.matchesApi.getMatchesCounter({limit: 20, ...query}).pipe(
                    tapResponse(
                        matchesCounter =>
                            this.patchState({
                                matchesCounter,
                                matchesCounterLoading: false,
                            }),
                        () => this.patchState({matchesCounterLoading: false}),
                    ),
                ),
            ),
        ),
    );

    readonly fetchTournamentsBySports = this.effect<void>($ =>
        $.pipe(
            tap(() =>
                this.patchState({
                    tournamentsBySportsLoading: true,
                    calendarActiveDate: new Date(this.queryParams().date!),
                    tournamentsBySports: null,
                }),
            ),
            tap(() => this.fetchMatchesCounter(this.queryParams())),
            switchMap(() =>
                this.matchesApi.getTournamentsBySports(this.queryParams()).pipe(
                    tapResponse(
                        tournamentsBySports => {
                            this.patchState({
                                tournamentsBySports,
                                tournamentsBySportsLoading: false,
                            });
                        },
                        () => this.patchState({tournamentsBySportsLoading: false}),
                    ),
                ),
            ),
        ),
    );

    readonly fetchTournamentsBySport = this.effect<void>($ =>
        $.pipe(
            tap(() =>
                this.patchState({
                    tournamentsBySportLoading: true,
                    tournamentsBySport: null,
                }),
            ),
            tap(() => this.fetchMatchesCounter(this.queryParams())),
            switchMap(() =>
                this.matchesApi.getTournamentsBySport(this.queryParams()).pipe(
                    tapResponse(
                        tournamentsBySport => {
                            this.patchState({
                                tournamentsBySport,
                                tournamentsBySportLoading: false,
                            });
                        },
                        () => this.patchState({tournamentsBySportLoading: false}),
                    ),
                ),
            ),
        ),
    );

    readonly tournamentsBySportsLoading = toSignal(
        this.select(({tournamentsBySportsLoading}) => tournamentsBySportsLoading),
        {initialValue: false},
    );

    readonly tournamentsBySports = toSignal(
        this.select(({tournamentsBySports}) => tournamentsBySports),
        {initialValue: null},
    );

    readonly tournamentsBySportLoading = toSignal(
        this.select(({tournamentsBySportLoading}) => tournamentsBySportLoading),
        {initialValue: false},
    );

    readonly tournamentsBySport = toSignal(
        this.select(({tournamentsBySport}) => tournamentsBySport),
        {initialValue: null},
    );

    readonly activeSportName = toSignal(
        this.select(({activeSportName}) => activeSportName),
        {initialValue: ''},
    );

    readonly activeSport = toSignal(
        this.select(({activeSport}) => activeSport),
        {initialValue: undefined},
    );

    readonly activeStatus = toSignal(
        this.select(({activeStatus}) => activeStatus),
        {initialValue: undefined},
    );

    readonly sports = toSignal(
        this.select(({sports}) => sports),
        {initialValue: []},
    );

    readonly calendarActiveDate = toSignal(
        this.select(({calendarActiveDate}) => calendarActiveDate),
        {initialValue: new Date()},
    );

    readonly matchesCounter$ = this.select(({matchesCounter}) => matchesCounter);
    readonly matchesCounterLoading$ = this.select(
        ({matchesCounterLoading}) => matchesCounterLoading,
    );

    constructor(
        @Inject(Router) router: Router,
        @Inject(SportsApi) private readonly sportsApi: SportsApi,
        @Inject(MatchesApi) private readonly matchesApi: MatchesApi,
        @Inject(COUNTRY_CODE_FALLBACK) readonly countryCodeFallback: string,
        @Inject(CURRENT_COUNTRY)
        private readonly currentCountry: BehaviorSubject<Country>,
        @Inject(MetaService)
        readonly metaService: MetaService,
    ) {
        super(initialState());
        this.fetchSports();

        this.currentCountry
            .pipe(
                // @todo: looks really bad, need another solution
                skip(2),
                filter(country => !!country.code && router.url.split('/').length < 4),
                takeUntilDestroyed(),
            )
            .subscribe(() => {
                this.setQueryParams({country: this.currentCountry.value.code});
            });
    }

    setActiveSportName(activeSportName: string): void {
        this.patchState({activeSportName});
        this.setActiveSport(activeSportName ? getKeySport(activeSportName) : undefined);
    }

    setActiveSport(activeSport: SportsEnum | undefined): void {
        this.patchState({activeSport});
        this.setQueryParams({sport: activeSport});
    }

    setActiveDate({date}: Partial<QueryParameters>): void {
        this.patchState({calendarActiveDate: new Date(date!)});
        this.setQueryParams({date});
    }

    setActiveStatus(activeStatus: MatchStatus | undefined): void {
        this.patchState({activeStatus});
        this.setQueryParams({status: activeStatus});
    }

    private setQueryParams(query: Partial<QueryParameters>): void {
        this.queryParams.set({...this.queryParams(), ...query});

        if (this.queryParams().sport) {
            this.fetchTournamentsBySport();
        } else {
            this.fetchTournamentsBySports();
        }
    }
}
