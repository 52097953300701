import { tuiAssert } from '@taiga-ui/cdk/classes';

/**
 * Clamps a value between two inclusive limits
 *
 * @param value
 * @param min lower limit
 * @param max upper limit
 */
function tuiClamp(value, min, max) {
    ngDevMode && tuiAssert.assert(!Number.isNaN(value));
    ngDevMode && tuiAssert.assert(!Number.isNaN(min));
    ngDevMode && tuiAssert.assert(!Number.isNaN(max));
    ngDevMode && tuiAssert.assert(max >= min);
    return Math.min(max, Math.max(min, value));
}

function tuiInRange(value, fromInclude, toExclude) {
    ngDevMode && tuiAssert.assert(!Number.isNaN(value));
    ngDevMode && tuiAssert.assert(!Number.isNaN(fromInclude));
    ngDevMode && tuiAssert.assert(!Number.isNaN(toExclude));
    ngDevMode && tuiAssert.assert(fromInclude < toExclude);
    return value >= fromInclude && value < toExclude;
}

/**
 * Normalizes any number to an integer within inclusive range
 *
 * @param value
 * @param min lower inclusive integer
 * @param max upper inclusive integer
 * @return an integer between min and max inclusive
 */
function tuiNormalizeToIntNumber(value, min, max) {
    ngDevMode && tuiAssert.assert(Number.isInteger(min));
    ngDevMode && tuiAssert.assert(Number.isInteger(max));
    ngDevMode && tuiAssert.assert(min <= max);
    if (Number.isNaN(value) || value <= min) {
        return min;
    }
    if (value >= max) {
        return max;
    }
    return Math.round(value);
}

/**
 * Rounds a number to the closest value in a fixed discrete series
 *
 * @param value
 * @param quantum series step
 */
function tuiQuantize(value, quantum) {
    ngDevMode && tuiAssert.assert(Number.isFinite(value));
    ngDevMode && tuiAssert.assert(Number.isFinite(quantum));
    ngDevMode && tuiAssert.assert(quantum > 0);
    const remainder = value % quantum;
    return remainder < quantum / 2 ? value - remainder : value + quantum - remainder;
}

const MAX_PRECISION = 292;
function tuiRound(value, precision = 0) {
    return calculate(value, precision, Math.round);
}
function tuiCeil(value, precision = 0) {
    return calculate(value, precision, Math.ceil);
}
function tuiFloor(value, precision = 0) {
    return calculate(value, precision, Math.floor);
}
function tuiTrunc(value, precision = 0) {
    return calculate(value, precision, Math.trunc);
}
/**
 * Rounding number to the set precision
 *
 * @param value
 * @param precision number of digits in a float part
 * @param func rounding function (round, floor, ceil)
 */
function calculate(value, precision, func) {
    if (value === Infinity) {
        return value;
    }
    ngDevMode && tuiAssert.assert(!Number.isNaN(value), `Value must be number`);
    ngDevMode &&
        tuiAssert.assert(Number.isInteger(precision), `Precision must be integer`);
    precision = Math.min(precision, MAX_PRECISION);
    const pair = `${value}e`.split(`e`);
    const tempValue = func(Number(`${pair[0]}e${Number(pair[1]) + precision}`));
    const processedPair = `${tempValue}e`.split(`e`);
    return Number(`${processedPair[0]}e${Number(processedPair[1]) - precision}`);
}

function tuiRoundWith({ value, precision, method, }) {
    switch (method) {
        case `round`:
            return tuiRound(value, precision);
        case `ceil`:
            return tuiCeil(value, precision);
        case `floor`:
            return tuiFloor(value, precision);
        default:
            return tuiTrunc(value, precision);
    }
}

/**
 * Calculates sum of any number of passed arguments
 */
function tuiSum(...args) {
    return args.reduce((a, b) => a + b, 0);
}

function tuiToInt(bool) {
    return bool ? 1 : 0;
}

function tuiToInteger(value) {
    return parseInt(value, 10);
}

/**
 * Converts angle in degrees to radians
 */
function tuiToRadians(deg) {
    return (deg * Math.PI) / 180;
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiCeil, tuiClamp, tuiFloor, tuiInRange, tuiNormalizeToIntNumber, tuiQuantize, tuiRound, tuiRoundWith, tuiSum, tuiToInt, tuiToInteger, tuiToRadians, tuiTrunc };

