// TODO: change type in v4.0
// eslint-disable-next-line no-restricted-syntax
var TuiAppearance;
(function (TuiAppearance) {
    TuiAppearance["Accent"] = "accent";
    TuiAppearance["Flat"] = "flat";
    TuiAppearance["Icon"] = "icon";
    TuiAppearance["Outline"] = "outline";
    TuiAppearance["Primary"] = "primary";
    TuiAppearance["Secondary"] = "secondary";
    TuiAppearance["SecondaryDestructive"] = "secondary-destructive";
    TuiAppearance["Table"] = "table";
    TuiAppearance["Textfield"] = "textfield";
    TuiAppearance["Whiteblock"] = "whiteblock";
    TuiAppearance["WhiteblockActive"] = "whiteblock-active";
})(TuiAppearance || (TuiAppearance = {}));

/** @deprecated not used anymore TODO: remove in 4.0 **/
// eslint-disable-next-line no-restricted-syntax
var TuiDropdownAnimation;
(function (TuiDropdownAnimation) {
    TuiDropdownAnimation["FadeInBottom"] = "fadeInBottom";
    TuiDropdownAnimation["FadeInTop"] = "fadeInTop";
})(TuiDropdownAnimation || (TuiDropdownAnimation = {}));

/**
 * @internal used in calendar, year picker and wrapper
 */
// TODO: change type in v4.0
// eslint-disable-next-line no-restricted-syntax
var TuiInteractiveState;
(function (TuiInteractiveState) {
    TuiInteractiveState["Active"] = "active";
    TuiInteractiveState["Disabled"] = "disabled";
    TuiInteractiveState["Hover"] = "hover";
    TuiInteractiveState["Readonly"] = "readonly";
})(TuiInteractiveState || (TuiInteractiveState = {}));

/**
 * @deprecated use union type TuiNotificationT instead
 */
// eslint-disable-next-line no-restricted-syntax
var TuiNotification;
(function (TuiNotification) {
    TuiNotification["Error"] = "error";
    TuiNotification["Info"] = "info";
    TuiNotification["Success"] = "success";
    TuiNotification["Warning"] = "warning";
})(TuiNotification || (TuiNotification = {}));

/**
 * @internal used in calendar and year picker
 */
// TODO: change type in v4.0
// eslint-disable-next-line no-restricted-syntax
var TuiRangeState;
(function (TuiRangeState) {
    TuiRangeState["End"] = "end";
    TuiRangeState["Single"] = "single";
    TuiRangeState["Start"] = "start";
})(TuiRangeState || (TuiRangeState = {}));

/**
 * Generated bundle index. Do not edit.
 */

export { TuiAppearance, TuiDropdownAnimation, TuiInteractiveState, TuiNotification, TuiRangeState };

