import {BannerModal} from '@nx-angular-standalone/shared/model';

export const getBannerSize = (
    breakpoint: string | null,
    banner: BannerModal | null,
): BannerModal | null => {
    if (!banner) {
        return null;
    }

    switch (breakpoint) {
        case 'desktopSmall':
            banner.image = banner.image2;
            break;
        case 'mobile':
            banner.image = banner.image3;
            break;
    }

    return banner;
};
