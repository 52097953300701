import {inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE} from '@ng-web-apis/common';
import {Bookmaker} from '@nx-angular-standalone/shared/model';
import {COUNTRY_CODE} from '@nx-angular-standalone/shared/util';
import {
    BehaviorSubject,
    catchError,
    finalize,
    Observable,
    of,
    startWith,
    Subject,
    switchMap,
} from 'rxjs';

import {BookmakersApi} from '../api/bookmakers.api';

@Injectable({providedIn: 'root'})
export class BookmakersService {
    private readonly _bookmakersApi = inject(BookmakersApi);
    private readonly _localStorage = inject(LOCAL_STORAGE);

    private readonly _bookmakers$ = new Subject<void>();
    readonly loading$ = new BehaviorSubject<boolean>(false);

    private get _key(): string {
        return (this._localStorage.getItem(COUNTRY_CODE) as string) || 'RU';
    }

    getBookmakers(): Observable<Bookmaker[]> {
        this.loading$.next(true);

        return this._bookmakers$.asObservable().pipe(
            startWith(''),
            switchMap(() =>
                this._bookmakersApi.get({country: this._key}).pipe(
                    catchError(() => of([])),
                    finalize(() => this.loading$.next(false)),
                ),
            ),
        );
    }

    updateBookmakers(): void {
        this._bookmakers$.next();
    }
}
