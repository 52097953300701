import {LabelValuePair} from '@nx-angular-standalone/shared/model';

export const defaultFaqs: Array<LabelValuePair<string>> = [
    {
        label: 'Как я могу делать ставки на спорт через ваш сайт?',
        value: `Вы найдете прямые ссылки на надежные платформы ставок. Просто выберите интересующее вас событие
                        и перейдите по ссылке для размещения ставки.`,
    },
    {
        label: 'Предоставляет ли сайт аналитику для ставок?',
        value: `Да, у нас вы найдете полную информацию по каждому матчу: Статистику по игрокам, статистические
                        данные всех предыдущих игр, бонусы и коэффициенты к каждому матчу и много другое.`,
    },
    {
        label: 'Могу ли я следить за ходом матчей в реальном времени на вашем сайте?',
        value: `Мы предоставляем обновления в реальном времени для всех матчей, чтобы вы могли отслеживать ход
                        игры и делать ставки в процессе.`,
    },
    {
        label: 'Есть ли на сайте специальные предложения или бонусы для ставок?',
        value: `Мы регулярно обновляем раздел специальных предложений, где вы можете найти бонусы и акции от
                        наших беттинг-партнеров.`,
    },
];

export const defaultFootballFaqs: Array<LabelValuePair<string>> = [
    {
        label: 'Как мне узнать расписание ближайших футбольных матчей?',
        value: 'На нашем сайте вы найдете полное расписание предстоящих матчей. Вы можете отфильтровать матчи по лиге, команде или дате, чтобы получить актуальную информацию о интересующих вас играх.',
    },
    {
        label: 'Предлагаете ли вы прогнозы на футбольные матчи?',
        value: 'Нет, но у нас вы можете найти всю возможную информацию по мачтам: Аналитику в реальном времени, а также за все прошлые матчи, статистику по игрокам и командам, коэффициенты и бонусы, и многое другое, что поможет вам сделать верную ставку на спорт.',
    },
    {
        label: 'Могу ли я делать ставки на матчи прямо на вашем сайте?',
        value: 'Наш сайт предлагает прямые ссылки на букмекерские конторы, где вы можете делать ставки. Мы предоставляем удобный доступ к ставкам, но прямых ставок на нашем сайте нет.',
    },
    {
        label: 'Как мне следить за результатами матчей в реальном времени?',
        value: 'На нашем сайте доступны обновления результатов матчей в реальном времени. Вы также можете подписаться на уведомления, чтобы получать свежие новости о матчах и изменениях счета.',
    },
    {
        label: 'Есть ли на вашем сайте информация о травмах игроков и составах команд?',
        value: 'Да, мы предоставляем последние новости о состоянии игроков и составах команд перед каждым матчем, что является важной информацией для принятия решений о ставках.',
    },
];

export const defaultVolleyballFaqs: Array<LabelValuePair<string>> = [
    {
        label: 'Как я могу следить за международными волейбольными турнирами?',
        value: 'На нашем сайте доступно полное расписание международных и национальных волейбольных турниров.',
    },
    {
        label: 'Предлагаете ли вы прогнозы на волейбольные матчи?',
        value: 'Нет, мы не предоставляем прогнозы для ставок на спорт на Волейбол, но у нас вы можете найти всю возможную информацию по мачтам: Аналитику в реальном времени, а также за все прошлые матчи, статистику по игрокам и командам, коэффициенты и бонусы, и многое другое, что поможет вам сделать верную ставку на спорт.',
    },
    {
        label: 'Можно ли делать ставки на волейбол через ваш сайт?',
        value: 'Мы предоставляем прямые ссылки на букмекерские конторы для ставок на волейбол.',
    },
];

export const defaultBasketballFaqs: Array<LabelValuePair<string>> = [
    {
        label: 'Как мне найти расписание баскетбольных игр?',
        value: 'В нашем Матч-центре доступно полное расписание баскетбольных матчей различных лиг и турниров.',
    },
    {
        label: 'Есть ли на сайте аналитика и статистика по баскетбольным матчам?',
        value: 'Мы предоставляем подробную статистику и аналитические обзоры для всех крупных баскетбольных игр.',
    },
    {
        label: 'Можно ли делать ставки на баскетбол через ваш сайт?',
        value: 'Да, на нашем сайте вы найдете ссылки на букмекерские конторы для ставок на баскетбол.',
    },
];

export const defaultHockeyFaqs: Array<LabelValuePair<string>> = [
    {
        label: 'Как узнать о предстоящих хоккейных матчах?',
        value: 'Посетите наш Матч-центр для актуального расписания всех важных хоккейных матчей.',
    },
    {
        label: 'Можно ли получать аналитические данные о хоккейных играх?',
        value: 'Да, мы предоставляем обзоры и аналитику по каждому значимому хоккейному событию.',
    },
    {
        label: 'Как делать ставки на хоккей через ваш сайт?',
        value: 'На нашем сайте вы найдете прямые ссылки на букмекерские конторы для ставок на хоккей.',
    },
];

export const defaultTennisFaqs: Array<LabelValuePair<string>> = [
    {
        label: 'Как мне узнать о расписании теннисных турниров?',
        value: 'В нашем теннисном Матч-центре вы найдете полное расписание турниров Grand Slam, ATP и WTA.',
    },
    {
        label: 'Предоставляете ли вы аналитику для теннисных матчей?',
        value: 'Да, мы предлагаем обзоры и прогнозы для всех значимых теннисных событий.',
    },
    {
        label: 'Как сделать ставки на теннис через ваш сайт?',
        value: 'Используйте наши прямые ссылки на букмекерские конторы для ставок на теннис.',
    },
];

export const defaultTableTennisFaqs: Array<LabelValuePair<string>> = [
    {
        label: 'Где я могу найти расписание настольного тенниса?',
        value: 'Наш Матч-центр предоставляет полное расписание международных и национальных турниров по настольному теннису.',
    },
    {
        label: 'Есть ли аналитика для матчей настольного тенниса?',
        value: 'Мы предлагаем детальные аналитические обзоры и прогнозы для важных событий настольного тенниса.',
    },
    {
        label: 'Могу ли я делать ставки на настольный теннис через ваш сайт?',
        value: 'Да, на InfoBet доступны ссылки на букмекерские конторы для ставок на настольный теннис.',
    },
];

export const defaultCybersportFaqs: Array<LabelValuePair<string>> = [
    {
        label: 'Как мне узнать о предстоящих киберспортивных турнирах?',
        value: 'Посетите наш Матч-центр для получения актуального расписания киберспортивных событий.',
    },
    {
        label: 'Предоставляете ли вы аналитику по киберспортивным матчам?',
        value: 'Да, мы предлагаем детальные обзоры и прогнозы для всех важных киберспортивных событий.',
    },
    {
        label: 'Могу ли я делать ставки на киберспорт через ваш сайт?',
        value: 'Да, на InfoBet доступны ссылки на букмекерские конторы для ставок на киберспортивные события.',
    },
];

export const defaultUfcFaqs: Array<LabelValuePair<string>> = [
    {
        label: 'Где я могу найти расписание боев UFC?',
        value: 'Наш Матч-центр предоставляет актуальное расписание всех значимых боев UFC.',
    },
    {
        label: 'Есть ли аналитика для боев UFC?',
        value: 'Мы предлагаем подробные обзоры и аналитические материалы для каждого важного поединка в UFC.',
    },
    {
        label: 'Как сделать ставки на бои UFC через ваш сайт?',
        value: 'Воспользуйтесь нашими прямыми ссылками на букмекерские конторы для ставок на события UFC.',
    },
];
