import {InjectionToken} from '@angular/core';
import {Country} from '@nx-angular-standalone/shared/model';
import {BehaviorSubject} from 'rxjs';

export const CURRENT_COUNTRY = new InjectionToken<BehaviorSubject<Country>>(
    'Current country',
);

export const CURRENT_COUNTRY_PROVIDER = {
    provide: CURRENT_COUNTRY,
    useValue: new BehaviorSubject<Country>({} as Country),
};
