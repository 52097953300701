import {SportsEnum} from '@nx-angular-standalone/shared/model';

export const getUrlSport = (sport: number): string => {
    const urlMap: Map<number, string> = new Map([
        [SportsEnum.Football, 'football'],
        [SportsEnum.Hockey, 'hockey'],
        [SportsEnum.Basketball, 'basketball'],
        [SportsEnum.Tennis, 'tennis'],
        [SportsEnum.Volleyball, 'volleyball'],
        [SportsEnum.Cybersport, 'cybersport'],
        [SportsEnum.TableTennis, 'table-tennis'],
        [SportsEnum.UFC, 'ufc'],
    ]);

    return urlMap.get(sport) as string;
};
