<!--noindex-->
<div class="countries">
    <h1>Выберите страну</h1>

    <tui-loader
        size="xxl"
        class="loader"
        [showLoader]="(isLoading$ | async) || false"
        [overlay]="true"
    >
        <div class="countries__all">
            <div
                *ngFor="let country of countries$ | async"
                class="country"
                [ngClass]="{currentCountry: currentCountry === country}"
                (click)="onCurrentCountry(country)"
            >
                <img
                    *ngIf="country.icon"
                    alt="{{ country.icon }}"
                    width="96"
                    height="70"
                    priority
                    [ngSrc]="country.icon"
                />
                <p>{{ country.name }}</p>
            </div>
        </div>
    </tui-loader>

    <button
        tuiButton
        type="submit"
        class="btn"
        [disabled]="currentCountry === null"
        (click)="selectedCountry()"
    >
        Подтвердить
    </button>
</div>
<!--/noindex-->
