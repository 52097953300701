import * as i0 from '@angular/core';
import { EventEmitter, Directive, Input, HostBinding, Output, Optional, Inject, Injectable, TemplateRef, ChangeDetectorRef, ComponentFactoryResolver, Injector, INJECTOR, ElementRef, ViewContainerRef, ViewChild } from '@angular/core';
import * as i1 from '@angular/forms';
import { NgModel } from '@angular/forms';
import { tuiAssert } from '@taiga-ui/cdk/classes';
import { EMPTY_FUNCTION } from '@taiga-ui/cdk/constants';
import { tuiIsPresent } from '@taiga-ui/cdk/utils';
import { Subject, merge, Observable, BehaviorSubject } from 'rxjs';
import { delay, startWith, map, filter, distinctUntilChanged, switchMap, takeUntil, ignoreElements, endWith, share } from 'rxjs/operators';
import { tuiIfMap } from '@taiga-ui/cdk/observables';
import { PolymorpheusTemplate } from '@tinkoff/ng-polymorpheus';
import * as i1$1 from '@taiga-ui/cdk/services';
import { TuiIdService } from '@taiga-ui/cdk/services';
import { TuiNoHostException } from '@taiga-ui/cdk/exceptions';
import { DOCUMENT } from '@angular/common';
const _c0 = ["viewContainer"];
const TUI = `tui_interactive_`;
/**
 * The most basic class for interactive components
 */
class AbstractTuiInteractive {
  constructor() {
    this.pseudoHover = null;
    this.pseudoActive = null;
    this.pseudoFocus = null;
    /**
     * Determines if component is focusable with keyboard.
     */
    this.focusable = true;
    this.nativeId = ``;
    /**
     * Emits 'true' on focus and 'false' on blur.
     */
    this.focusedChange = new EventEmitter();
    this.focusVisibleChange = new EventEmitter();
    this.focusVisible = false;
    this.autoIdString = `${TUI}${AbstractTuiInteractive.autoId++}${Date.now()}`;
  }
  get computedDisabled() {
    return this.disabled;
  }
  get computedFocusable() {
    return !this.computedDisabled && (this.focusable || this.focused);
  }
  get computedFocused() {
    var _a;
    return !this.computedDisabled && ((_a = this.pseudoFocus) !== null && _a !== void 0 ? _a : this.focused);
  }
  get computedFocusVisible() {
    var _a;
    return !this.computedDisabled && ((_a = this.pseudoFocus) !== null && _a !== void 0 ? _a : this.focusVisible);
  }
  // TODO: 3.0 Consider removing since native input is exposed
  get id() {
    return this.nativeId || this.autoIdString;
  }
  updateFocused(focused) {
    this.focusedChange.emit(focused);
  }
  updateFocusVisible(focusVisible) {
    if (this.focusVisible === focusVisible) {
      return;
    }
    this.focusVisible = focusVisible;
    this.focusVisibleChange.emit(focusVisible);
  }
}
AbstractTuiInteractive.autoId = 0;
AbstractTuiInteractive.ɵfac = function AbstractTuiInteractive_Factory(t) {
  return new (t || AbstractTuiInteractive)();
};
AbstractTuiInteractive.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AbstractTuiInteractive,
  hostVars: 7,
  hostBindings: function AbstractTuiInteractive_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("data-focused", ctx.pseudoFocus);
      i0.ɵɵclassProp("_disabled", ctx.computedDisabled)("_focused", ctx.computedFocused)("_focus-visible", ctx.computedFocusVisible);
    }
  },
  inputs: {
    pseudoHover: "pseudoHover",
    pseudoActive: "pseudoActive",
    pseudoFocus: "pseudoFocus",
    focusable: "focusable",
    nativeId: "nativeId"
  },
  outputs: {
    focusedChange: "focusedChange",
    focusVisibleChange: "focusVisibleChange"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiInteractive, [{
    type: Directive
  }], function () {
    return [];
  }, {
    pseudoHover: [{
      type: Input
    }],
    pseudoActive: [{
      type: Input
    }],
    pseudoFocus: [{
      type: Input
    }, {
      type: HostBinding,
      args: [`attr.data-focused`]
    }],
    focusable: [{
      type: Input
    }],
    nativeId: [{
      type: Input
    }],
    focusedChange: [{
      type: Output
    }],
    focusVisibleChange: [{
      type: Output
    }],
    computedDisabled: [{
      type: HostBinding,
      args: [`class._disabled`]
    }],
    computedFocused: [{
      type: HostBinding,
      args: [`class._focused`]
    }],
    computedFocusVisible: [{
      type: HostBinding,
      args: [`class._focus-visible`]
    }]
  });
})();
class AbstractTuiValueTransformer {}

/**
 * Basic ControlValueAccessor class to build form components upon
 */
class AbstractTuiControl extends AbstractTuiInteractive {
  constructor(ngControl, cdr, valueTransformer) {
    super();
    this.ngControl = ngControl;
    this.cdr = cdr;
    this.valueTransformer = valueTransformer;
    this.refresh$ = new Subject();
    this.onTouched = EMPTY_FUNCTION;
    this.onChange = EMPTY_FUNCTION;
    this.fallbackValue = this.getFallbackValue();
    this.destroy$ = new Subject();
    this.readOnly = false;
    this.pseudoInvalid = null;
    if (ngDevMode && this.ngControl === null) {
      tuiAssert.assert(false, `NgControl not injected in ${this.constructor.name}!\n`, `Use [(ngModel)] or [formControl] or formControlName for correct work.`);
    }
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }
  get computedInvalid() {
    return this.interactive && (this.pseudoInvalid !== null ? this.pseudoInvalid : this.touched && this.invalid);
  }
  get value() {
    var _a;
    return (_a = this.previousInternalValue) !== null && _a !== void 0 ? _a : this.fallbackValue;
  }
  set value(value) {
    this.updateValue(value);
  }
  get safeCurrentValue() {
    var _a;
    return (_a = this.rawValue) !== null && _a !== void 0 ? _a : this.fallbackValue;
  }
  get invalid() {
    return this.safeNgControlData(({
      invalid
    }) => invalid, false);
  }
  get valid() {
    return this.safeNgControlData(({
      valid
    }) => valid, false);
  }
  get touched() {
    return this.safeNgControlData(({
      touched
    }) => touched, false);
  }
  get disabled() {
    return this.safeNgControlData(({
      disabled
    }) => disabled, false);
  }
  get interactive() {
    return !this.readOnly && !this.computedDisabled;
  }
  get control() {
    return this.safeNgControlData(({
      control
    }) => control, null);
  }
  get computedName() {
    var _a, _b;
    return (_b = (_a = this.controlName) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : null;
  }
  get controlName() {
    var _a, _b, _c;
    return (_c = (_b = (_a = this.ngControl) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : null;
  }
  get rawValue() {
    const {
      ngControl
    } = this;
    if (ngControl === null) {
      return undefined;
    }
    const controlValue = ngControl instanceof NgModel && this.previousInternalValue === undefined ? ngControl.viewModel : ngControl.value;
    return this.fromControlValue(controlValue);
  }
  ngOnInit() {
    this.refresh$.pipe(delay(0), startWith(null), map(() => {
      var _a;
      return (_a = this.ngControl) === null || _a === void 0 ? void 0 : _a.control;
    }), filter(tuiIsPresent), distinctUntilChanged(), switchMap(control => merge(control.valueChanges, control.statusChanges)), takeUntil(this.destroy$)).subscribe(() => {
      this.refreshLocalValue(this.safeCurrentValue);
    });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  checkControlUpdate() {
    this.cdr.markForCheck();
  }
  registerOnChange(onChange) {
    this.onChange = componentValue => {
      onChange(this.toControlValue(componentValue));
    };
    this.refresh$.next();
  }
  registerOnTouched(onTouched) {
    this.onTouched = onTouched;
  }
  setDisabledState() {
    this.checkControlUpdate();
  }
  writeValue(value) {
    const controlValue = this.ngControl instanceof NgModel && this.previousInternalValue === undefined ? this.ngControl.model : value;
    this.refreshLocalValue(this.fromControlValue(controlValue));
  }
  updateFocused(focused) {
    if (!focused) {
      this.controlMarkAsTouched();
    }
    super.updateFocused(focused);
  }
  /**
   * @deprecated use `value` setter
   */
  updateValue(value) {
    if (this.disabled || this.valueIdenticalComparator(this.value, value)) {
      return;
    }
    this.previousInternalValue = value;
    this.controlSetValue(value);
  }
  valueIdenticalComparator(oldValue, newValue) {
    return oldValue === newValue;
  }
  safeNgControlData(extractor, defaultFieldValue) {
    var _a;
    return (_a = this.ngControl && extractor(this.ngControl)) !== null && _a !== void 0 ? _a : defaultFieldValue;
  }
  controlMarkAsTouched() {
    this.onTouched();
    this.checkControlUpdate();
  }
  controlSetValue(value) {
    this.onChange(value);
    this.checkControlUpdate();
  }
  refreshLocalValue(value) {
    this.previousInternalValue = value;
    this.checkControlUpdate();
  }
  fromControlValue(controlValue) {
    return this.valueTransformer ? this.valueTransformer.fromControlValue(controlValue) : controlValue;
  }
  toControlValue(componentValue) {
    return this.valueTransformer ? this.valueTransformer.toControlValue(componentValue) : componentValue;
  }
}
AbstractTuiControl.ɵfac = function AbstractTuiControl_Factory(t) {
  return new (t || AbstractTuiControl)(i0.ɵɵdirectiveInject(i1.NgControl, 8), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(AbstractTuiValueTransformer, 8));
};
AbstractTuiControl.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AbstractTuiControl,
  hostVars: 4,
  hostBindings: function AbstractTuiControl_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("_readonly", ctx.readOnly)("_invalid", ctx.computedInvalid);
    }
  },
  inputs: {
    readOnly: "readOnly",
    pseudoInvalid: "pseudoInvalid"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiControl, [{
    type: Directive
  }], function () {
    return [{
      type: i1.NgControl,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [AbstractTuiValueTransformer]
      }]
    }];
  }, {
    readOnly: [{
      type: Input
    }, {
      type: HostBinding,
      args: [`class._readonly`]
    }],
    pseudoInvalid: [{
      type: Input
    }],
    computedInvalid: [{
      type: HostBinding,
      args: [`class._invalid`]
    }]
  });
})();
function tuiAsControl(useExisting) {
  return {
    provide: AbstractTuiControl,
    useExisting
  };
}
class AbstractTuiController {
  constructor() {
    this.change$ = new Subject();
  }
  ngOnChanges() {
    this.change$.next();
  }
}
AbstractTuiController.ɵfac = function AbstractTuiController_Factory(t) {
  return new (t || AbstractTuiController)();
};
AbstractTuiController.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AbstractTuiController,
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiController, [{
    type: Directive
  }], null, null);
})();
class AbstractTuiDialogService extends Observable {
  constructor(idService) {
    super(observer => this.dialogs$.subscribe(observer));
    this.idService = idService;
    this.dialogs$ = new BehaviorSubject([]);
  }
  open(content, options = {}) {
    return new Observable(observer => {
      const completeWith = result => {
        observer.next(result);
        observer.complete();
      };
      const dialog = Object.assign(Object.assign(Object.assign({}, this.defaultOptions), options), {
        content,
        completeWith,
        $implicit: observer,
        component: this.component,
        createdAt: Date.now(),
        id: this.idService.generate()
      });
      this.dialogs$.next([...this.dialogs$.value, dialog]);
      return () => {
        this.dialogs$.next(this.dialogs$.value.filter(item => item !== dialog));
      };
    });
  }
}
AbstractTuiDialogService.ɵfac = function AbstractTuiDialogService_Factory(t) {
  return new (t || AbstractTuiDialogService)(i0.ɵɵinject(TuiIdService));
};
AbstractTuiDialogService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AbstractTuiDialogService,
  factory: AbstractTuiDialogService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiDialogService, [{
    type: Injectable
  }], function () {
    return [{
      type: i1$1.TuiIdService,
      decorators: [{
        type: Inject,
        args: [TuiIdService]
      }]
    }];
  }, null);
})();
class AbstractTuiDialogDirective extends PolymorpheusTemplate {
  constructor(templateRef, cdr, service) {
    super(templateRef, cdr);
    this.service = service;
    this.open$ = new Subject();
    this.options = {};
    this.open = false;
    this.openChange = this.open$.pipe(distinctUntilChanged(), tuiIfMap(() => this.service.open(this, this.options).pipe(ignoreElements(), endWith(false))), share());
  }
  ngOnChanges() {
    this.open$.next(this.open);
  }
}
AbstractTuiDialogDirective.ɵfac = function AbstractTuiDialogDirective_Factory(t) {
  return new (t || AbstractTuiDialogDirective)(i0.ɵɵdirectiveInject(TemplateRef), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(AbstractTuiDialogService));
};
AbstractTuiDialogDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AbstractTuiDialogDirective,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiDialogDirective, [{
    type: Directive
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Inject,
        args: [TemplateRef]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: AbstractTuiDialogService,
      decorators: [{
        type: Inject,
        args: [AbstractTuiDialogService]
      }]
    }];
  }, null);
})();
class AbstractTuiMultipleControl extends AbstractTuiControl {
  clear() {
    this.value = [];
  }
  getFallbackValue() {
    return [];
  }
}
AbstractTuiMultipleControl.ɵfac = /* @__PURE__ */(() => {
  let ɵAbstractTuiMultipleControl_BaseFactory;
  return function AbstractTuiMultipleControl_Factory(t) {
    return (ɵAbstractTuiMultipleControl_BaseFactory || (ɵAbstractTuiMultipleControl_BaseFactory = i0.ɵɵgetInheritedFactory(AbstractTuiMultipleControl)))(t || AbstractTuiMultipleControl);
  };
})();
AbstractTuiMultipleControl.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AbstractTuiMultipleControl,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiMultipleControl, [{
    type: Directive
  }], null, null);
})();
class AbstractTuiNullableControl extends AbstractTuiControl {
  getFallbackValue() {
    return null;
  }
}
AbstractTuiNullableControl.ɵfac = /* @__PURE__ */(() => {
  let ɵAbstractTuiNullableControl_BaseFactory;
  return function AbstractTuiNullableControl_Factory(t) {
    return (ɵAbstractTuiNullableControl_BaseFactory || (ɵAbstractTuiNullableControl_BaseFactory = i0.ɵɵgetInheritedFactory(AbstractTuiNullableControl)))(t || AbstractTuiNullableControl);
  };
})();
AbstractTuiNullableControl.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AbstractTuiNullableControl,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiNullableControl, [{
    type: Directive
  }], null, null);
})();

/**
 * Abstract service for displaying portals
 */
class AbstractTuiPortalService {
  get safeHost() {
    if (!this.host) {
      throw new TuiNoHostException();
    }
    return this.host;
  }
  attach(host) {
    this.host = host;
  }
  add(component) {
    return this.safeHost.addComponentChild(component);
  }
  remove({
    hostView
  }) {
    hostView.destroy();
  }
  addTemplate(templateRef, context) {
    return this.safeHost.addTemplateChild(templateRef, context);
  }
  removeTemplate(viewRef) {
    viewRef.destroy();
  }
}
AbstractTuiPortalService.ɵfac = function AbstractTuiPortalService_Factory(t) {
  return new (t || AbstractTuiPortalService)();
};
AbstractTuiPortalService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AbstractTuiPortalService,
  factory: AbstractTuiPortalService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiPortalService, [{
    type: Injectable
  }], null, null);
})();

/**
 * Abstract class for host element for dynamically created portals.
 */
class AbstractTuiPortalHostComponent {
  constructor(injector, el, portalService) {
    this.injector = injector;
    this.el = el;
    portalService.attach(this);
  }
  get clientRect() {
    return this.el.nativeElement.getBoundingClientRect();
  }
  addComponentChild(component) {
    const parent = component.createInjector(this.injector);
    const resolver = parent.get(ComponentFactoryResolver);
    const factory = resolver.resolveComponentFactory(component.component);
    const providers = [{
      provide: AbstractTuiPortalHostComponent,
      useValue: this
    }];
    const injector = Injector.create({
      parent,
      providers
    });
    const ref = this.vcr.createComponent(factory, undefined, injector);
    ref.changeDetectorRef.detectChanges();
    return ref;
  }
  addTemplateChild(templateRef, context) {
    return this.vcr.createEmbeddedView(templateRef, context);
  }
}
AbstractTuiPortalHostComponent.ɵfac = function AbstractTuiPortalHostComponent_Factory(t) {
  return new (t || AbstractTuiPortalHostComponent)(i0.ɵɵdirectiveInject(INJECTOR), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(AbstractTuiPortalService));
};
AbstractTuiPortalHostComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AbstractTuiPortalHostComponent,
  viewQuery: function AbstractTuiPortalHostComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5, ViewContainerRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.vcr = _t.first);
    }
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiPortalHostComponent, [{
    type: Directive
  }], function () {
    return [{
      type: i0.Injector,
      decorators: [{
        type: Inject,
        args: [INJECTOR]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: AbstractTuiPortalService,
      decorators: [{
        type: Inject,
        args: [AbstractTuiPortalService]
      }]
    }];
  }, {
    vcr: [{
      type: ViewChild,
      args: [`viewContainer`, {
        read: ViewContainerRef
      }]
    }]
  });
})();

/**
 * Use this abstract class to create your own toggleable themes.
 * A component extending this class must have CSS variables definitions
 * and have ViewEncapsulation set to NONE. A boolean input allows to
 * switch theme on or off.
 */
class AbstractTuiThemeSwitcher {
  constructor(doc) {
    this.doc = doc;
    if (this.style !== null) {
      this.addTheme();
      return;
    }
    const styles = this.doc.head.querySelectorAll(`style`);
    this.constructor.style = styles[styles.length - 1];
  }
  get style() {
    return this.constructor.style;
  }
  ngOnDestroy() {
    this.removeTheme();
  }
  addTheme() {
    if (this.style) {
      this.doc.head.appendChild(this.style);
    }
  }
  removeTheme() {
    var _a;
    (_a = this.style) === null || _a === void 0 ? void 0 : _a.remove();
  }
}
AbstractTuiThemeSwitcher.style = null;
AbstractTuiThemeSwitcher.ɵfac = function AbstractTuiThemeSwitcher_Factory(t) {
  return new (t || AbstractTuiThemeSwitcher)(i0.ɵɵdirectiveInject(DOCUMENT));
};
AbstractTuiThemeSwitcher.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AbstractTuiThemeSwitcher
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiThemeSwitcher, [{
    type: Directive
  }], function () {
    return [{
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractTuiControl, AbstractTuiController, AbstractTuiDialogDirective, AbstractTuiDialogService, AbstractTuiInteractive, AbstractTuiMultipleControl, AbstractTuiNullableControl, AbstractTuiPortalHostComponent, AbstractTuiPortalService, AbstractTuiThemeSwitcher, AbstractTuiValueTransformer, tuiAsControl };
