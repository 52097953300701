export const getCurrentDate = (date?: Date): string => {
    const today = date ?? new Date();

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

export const getCurrentDateFullText = (date?: Date): string => {
    const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
    ];

    const currentDate = date ?? new Date();
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();

    const formattedDate = `${day} ${month} ${year} года`;

    return formattedDate;
};

export const getCurrentYear = (): string => {
    return String(new Date().getFullYear());
};

export const isTodayDate = (date: Date): boolean => {
    const currentDay = getCurrentDate();
    const activeDay = getCurrentDate(date);

    return currentDay === activeDay;
};
