export const convertToMMSS = (seconds: number | null): string => {
    if (seconds === null) {
        return '';
    }

    let minutes = String(Math.floor(seconds / 60));
    let remainingSeconds = String(seconds % 60);

    if (Number(minutes) < 10) {
        minutes = `0${minutes}`;
    }

    if (Number(remainingSeconds) < 10) {
        remainingSeconds = `0${remainingSeconds}`;
    }

    return `${minutes}:${remainingSeconds}`;
};
