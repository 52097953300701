import {inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE} from '@ng-web-apis/common';
import {News, NewsBySport, QueryParameters} from '@nx-angular-standalone/shared/model';
import {COUNTRY_CODE} from '@nx-angular-standalone/shared/util';
import {
    BehaviorSubject,
    catchError,
    filter,
    finalize,
    map,
    Observable,
    of,
    startWith,
    Subject,
    switchMap,
    tap,
} from 'rxjs';

import {NewsApi} from '../api/news.api';

@Injectable({providedIn: 'root'})
export class NewsService {
    private readonly _newsApi = inject(NewsApi);
    private readonly _localStorage = inject(LOCAL_STORAGE);

    private readonly _newBySport$ = new Subject<void>();

    private get _key(): string {
        return (this._localStorage.getItem(COUNTRY_CODE) as string) || 'RU';
    }

    private _queryParameters: Partial<QueryParameters> = {
        limit: 15,
        country: this._key,
    };

    readonly queryParameters$ = new BehaviorSubject<Partial<QueryParameters>>(
        this._queryParameters,
    );

    readonly detailNews$ = new BehaviorSubject<News | null>(null);
    readonly detailNewsId$ = new BehaviorSubject<number | null>(null);

    readonly loading$ = new BehaviorSubject<boolean>(false);
    readonly upLoading$ = new BehaviorSubject<boolean>(true);

    getNews(): Observable<News[]> {
        this.loading$.next(true);

        return this.queryParameters$.asObservable().pipe(
            filter(Boolean),
            tap(() => {
                this.loading$.next(true);
            }),
            switchMap(params => {
                return this._newsApi.get(params).pipe(
                    map(({results}) => results),
                    catchError(() => of([])),
                    finalize(() => this.loading$.next(false)),
                );
            }),
        );
    }

    getNewsBySport(): Observable<NewsBySport[]> {
        return this._newBySport$.asObservable().pipe(
            startWith(''),
            tap(() => this.upLoading$.next(true)),
            switchMap(() =>
                this._newsApi.getNewsBySport({country: this._key}).pipe(
                    catchError(() => of([])),
                    finalize(() => this.upLoading$.next(false)),
                ),
            ),
        );
    }

    updateNews(limit: number): void {
        if (this._queryParameters.limit && limit !== 5 && limit !== 2) {
            this._queryParameters.limit += limit;
        } else {
            this._queryParameters.limit = limit;
        }

        this._queryParameters.country = this._key;
        this.queryParameters$.next(this._queryParameters);
    }

    returnDefaultQueryParams(): void {
        this._queryParameters = {
            limit: 15,
            country: this._key,
        };
        this.queryParameters$.next(this._queryParameters);
        this._newBySport$.next();
    }
}
