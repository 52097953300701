import { InjectionToken, inject } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { share, startWith, map, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

const WINDOW = new InjectionToken('An abstraction over global window object', {
    factory: () => {
        const { defaultView } = inject(DOCUMENT);
        if (!defaultView) {
            throw new Error('Window is not available');
        }
        return defaultView;
    },
});

const ANIMATION_FRAME = new InjectionToken('Shared Observable based on `window.requestAnimationFrame`', {
    factory: () => {
        const { requestAnimationFrame, cancelAnimationFrame } = inject(WINDOW);
        const animationFrame$ = new Observable(subscriber => {
            let id = NaN;
            const callback = (timestamp) => {
                subscriber.next(timestamp);
                id = requestAnimationFrame(callback);
            };
            id = requestAnimationFrame(callback);
            return () => {
                cancelAnimationFrame(id);
            };
        });
        return animationFrame$.pipe(share());
    },
});

const CACHES = new InjectionToken('An abstraction over window.caches object', {
    factory: () => inject(WINDOW).caches,
});

const CRYPTO = new InjectionToken('An abstraction over window.crypto object', {
    factory: () => inject(WINDOW).crypto,
});

const CSS = new InjectionToken('An abstraction over window.CSS object', {
    factory: () => inject(WINDOW).CSS || {
        escape: v => v,
        supports: () => false,
    },
});

const HISTORY = new InjectionToken('An abstraction over window.history object', {
    factory: () => inject(WINDOW).history,
});

const LOCAL_STORAGE = new InjectionToken('An abstraction over window.localStorage object', {
    factory: () => inject(WINDOW).localStorage,
});

const LOCATION = new InjectionToken('An abstraction over window.location object', {
    factory: () => inject(WINDOW).location,
});

const NAVIGATOR = new InjectionToken('An abstraction over window.navigator object', {
    factory: () => inject(WINDOW).navigator,
});

const MEDIA_DEVICES = new InjectionToken('An abstraction over window.navigator.mediaDevices object', {
    factory: () => inject(NAVIGATOR).mediaDevices,
});

const NETWORK_INFORMATION = new InjectionToken('An abstraction over window.navigator.connection object', {
    // @ts-ignore
    factory: () => inject(NAVIGATOR).connection || null,
});

const PAGE_VISIBILITY = new InjectionToken('Shared Observable based on `document visibility changed`', {
    factory: () => {
        const documentRef = inject(DOCUMENT);
        return fromEvent(documentRef, 'visibilitychange').pipe(startWith(0), map(() => documentRef.visibilityState !== 'hidden'), distinctUntilChanged(), shareReplay({ refCount: false, bufferSize: 1 }));
    },
});

const PERFORMANCE = new InjectionToken('An abstraction over window.performance object', {
    factory: () => inject(WINDOW).performance,
});

const SCREEN = new InjectionToken('An abstraction over window.screen object', {
    factory: () => inject(WINDOW).screen,
});

const SESSION_STORAGE = new InjectionToken('An abstraction over window.sessionStorage object', {
    factory: () => inject(WINDOW).sessionStorage,
});

const SPEECH_RECOGNITION = new InjectionToken('An abstraction over SpeechRecognition class', {
    factory: () => {
        const windowRef = inject(WINDOW);
        return windowRef.speechRecognition || windowRef.webkitSpeechRecognition || null;
    },
});

const SPEECH_SYNTHESIS = new InjectionToken('An abstraction over window.speechSynthesis object', {
    factory: () => inject(WINDOW).speechSynthesis,
});

const USER_AGENT = new InjectionToken('An abstraction over window.navigator.userAgent object', {
    factory: () => inject(NAVIGATOR).userAgent,
});

/**
 * Generated bundle index. Do not edit.
 */

export { ANIMATION_FRAME, CACHES, CRYPTO, CSS, HISTORY, LOCAL_STORAGE, LOCATION, MEDIA_DEVICES, NAVIGATOR, NETWORK_INFORMATION, PAGE_VISIBILITY, PERFORMANCE, SCREEN, SESSION_STORAGE, SPEECH_RECOGNITION, SPEECH_SYNTHESIS, USER_AGENT, WINDOW };

