import {LabelValuePair} from '@nx-angular-standalone/shared/model';

import {removeEmptyValues} from './remove-empty-values';

export const WEBSITE_JSON_LD_ID = 'website' as const;
export const WEBSITE_JSON_LD = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'InfoBet - Ставки на Спорт, Новости, Прямые Трансляции и Аналитика',
    description:
        'InfoBet - ставки на спорт с Коэффициентами, Бонусы от букмекеров, Новости, Прямые трансляции со статистикой и Аналитикой по каждому матчу.',
};

export const FAQ_JSON_LD_ID = 'faq' as const;
export const createFaqJsonLd = (
    list: Array<LabelValuePair<string>>,
): Record<string, any> => {
    return {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: list.map(({label, value}) => ({
            '@type': 'Question',
            name: label,
            acceptedAnswer: {'@type': 'Answer', text: value},
        })),
    };
};

export const NEWS_JSON_LD_ID = 'news' as const;
export const createNewsJsonLd = (
    headline: string,
    datePublished: string,
    name: string,
    url: string,
    imageUrl: string,
): Record<string, any> => {
    return removeEmptyValues({
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': url,
        },
        headline,
        datePublished,
        image: imageUrl,
        author: {
            '@type': 'Person',
            name,
        },
    });
};

export const EVENT_JSON_LD_ID = 'event' as const;
export const createEventJsonLd = (
    name: string,
    description: string,
    startDate: string,
    url: string,
): Record<string, any> => {
    return removeEmptyValues({
        '@context': 'https://schema.org',
        '@type': 'Event',
        name,
        description,
        startDate,
        eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
        location: {
            '@type': 'VirtualLocation',
            url,
        },
    });
};
