import {HttpClient} from '@angular/common/http';
import {inject, Injector, runInInjectionContext, Signal} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {catchError, EMPTY, map, tap} from 'rxjs';

import {assertInjector} from './assert-injector';

export const injectUserCountrySignal = (injector?: Injector): Signal<string | null> => {
    injector = assertInjector(injectUserCountrySignal, injector);
    const url = 'https://ipinfo.io/json';

    return runInInjectionContext(injector, () => {
        return toSignal(
            inject(HttpClient)
                .get<{country: string}>(url)
                .pipe(
                    map(({country}) => country?.toUpperCase()),
                    catchError(() => EMPTY),
                    tap(country => console.info(`User country code is: ${country}`)),
                ),
            {initialValue: null},
        );
    });
};
