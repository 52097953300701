import {HttpClient, HttpHeaders} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {ENVIRONMENT} from '@nx-angular-standalone/shared/environments';
import {QueryParameters} from '@nx-angular-standalone/shared/model';
import {removeEmptyValues} from '@nx-angular-standalone/shared/util';
import {Observable} from 'rxjs';

@Injectable()
export abstract class BaseCrudApi<T> {
    protected abstract readonly suffix: string;

    protected readonly http = inject(HttpClient);
    protected readonly environment = inject(ENVIRONMENT);

    protected readonly headers = new HttpHeaders()
        .set('X-Access-Token', this.environment.xAccessToken)
        .set('Authorization', this.environment.authorization);

    get(params: Partial<QueryParameters> = {}): Observable<T> {
        return this.http.get<T>(`${this.environment.baseUrl}/${this.suffix}/`, {
            params: removeEmptyValues(params),
            headers: this.headers,
        });
    }

    getById<U>(id: number | string): Observable<U> {
        return this.http.get<U>(`${this.environment.baseUrl}/${this.suffix}/${id}/`, {
            headers: this.headers,
        });
    }

    create<U>(body: U): Observable<T> {
        return this.http.post<T>(`${this.environment.baseUrl}/${this.suffix}/`, body);
    }

    update<U>(body: U): Observable<T> {
        return this.http.patch<T>(`${this.environment.baseUrl}/${this.suffix}/`, body);
    }

    delete(id: number | string): Observable<T> {
        return this.http.delete<T>(`${this.environment.baseUrl}/${this.suffix}/${id}/`);
    }
}
