import {InjectionToken} from '@angular/core';
import {MetaConfig, MetaConfigOg} from '@nx-angular-standalone/shared/model';

const title = 'InfoBet - Ставки на Спорт, Новости, Прямые Трансляции и Аналитика';
const description =
    'InfoBet - ставки на спорт с Коэффициентами, Бонусы от букмекеров, Новости, Прямые трансляции со статистикой и Аналитикой по каждому матчу.';

export const META_CONFIG = new InjectionToken<MetaConfig>('META_CONFIG', {
    providedIn: 'root',
    factory: (): MetaConfig => ({
        title,
        description,
    }),
});
export const META_CONFIG_OG = new InjectionToken<MetaConfigOg>('META_CONFIG_OG', {
    providedIn: 'root',
    factory: (): MetaConfigOg => ({
        title,
        description,
        type: 'website',
        image: '/assets/svg/info-bet-logo.svg',
        imageType: 'image/jpeg',
        imageWidth: '75',
        imageHeight: '31',
    }),
});
