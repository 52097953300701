// TODO: change type in v4.0
// eslint-disable-next-line no-restricted-syntax
var TuiCountryIsoCode;
(function (TuiCountryIsoCode) {
    TuiCountryIsoCode["AD"] = "AD";
    TuiCountryIsoCode["AE"] = "AE";
    TuiCountryIsoCode["AF"] = "AF";
    TuiCountryIsoCode["AG"] = "AG";
    TuiCountryIsoCode["AI"] = "AI";
    TuiCountryIsoCode["AL"] = "AL";
    TuiCountryIsoCode["AM"] = "AM";
    TuiCountryIsoCode["AO"] = "AO";
    TuiCountryIsoCode["AR"] = "AR";
    TuiCountryIsoCode["AT"] = "AT";
    TuiCountryIsoCode["AU"] = "AU";
    TuiCountryIsoCode["AW"] = "AW";
    TuiCountryIsoCode["AZ"] = "AZ";
    TuiCountryIsoCode["BA"] = "BA";
    TuiCountryIsoCode["BB"] = "BB";
    TuiCountryIsoCode["BD"] = "BD";
    TuiCountryIsoCode["BE"] = "BE";
    TuiCountryIsoCode["BF"] = "BF";
    TuiCountryIsoCode["BG"] = "BG";
    TuiCountryIsoCode["BH"] = "BH";
    TuiCountryIsoCode["BI"] = "BI";
    TuiCountryIsoCode["BJ"] = "BJ";
    TuiCountryIsoCode["BL"] = "BL";
    TuiCountryIsoCode["BM"] = "BM";
    TuiCountryIsoCode["BN"] = "BN";
    TuiCountryIsoCode["BO"] = "BO";
    TuiCountryIsoCode["BQ"] = "BQ";
    TuiCountryIsoCode["BR"] = "BR";
    TuiCountryIsoCode["BS"] = "BS";
    TuiCountryIsoCode["BT"] = "BT";
    TuiCountryIsoCode["BW"] = "BW";
    TuiCountryIsoCode["BY"] = "BY";
    TuiCountryIsoCode["BZ"] = "BZ";
    TuiCountryIsoCode["CA"] = "CA";
    TuiCountryIsoCode["CD"] = "CD";
    TuiCountryIsoCode["CF"] = "CF";
    TuiCountryIsoCode["CG"] = "CG";
    TuiCountryIsoCode["CH"] = "CH";
    TuiCountryIsoCode["CI"] = "CI";
    TuiCountryIsoCode["CL"] = "CL";
    TuiCountryIsoCode["CM"] = "CM";
    TuiCountryIsoCode["CN"] = "CN";
    TuiCountryIsoCode["CO"] = "CO";
    TuiCountryIsoCode["CR"] = "CR";
    TuiCountryIsoCode["CU"] = "CU";
    TuiCountryIsoCode["CV"] = "CV";
    TuiCountryIsoCode["CW"] = "CW";
    TuiCountryIsoCode["CY"] = "CY";
    TuiCountryIsoCode["CZ"] = "CZ";
    TuiCountryIsoCode["DE"] = "DE";
    TuiCountryIsoCode["DJ"] = "DJ";
    TuiCountryIsoCode["DK"] = "DK";
    TuiCountryIsoCode["DM"] = "DM";
    TuiCountryIsoCode["DO"] = "DO";
    TuiCountryIsoCode["DZ"] = "DZ";
    TuiCountryIsoCode["EC"] = "EC";
    TuiCountryIsoCode["EE"] = "EE";
    TuiCountryIsoCode["EG"] = "EG";
    TuiCountryIsoCode["ER"] = "ER";
    TuiCountryIsoCode["ES"] = "ES";
    TuiCountryIsoCode["ET"] = "ET";
    TuiCountryIsoCode["FI"] = "FI";
    TuiCountryIsoCode["FJ"] = "FJ";
    TuiCountryIsoCode["FK"] = "FK";
    TuiCountryIsoCode["FM"] = "FM";
    TuiCountryIsoCode["FR"] = "FR";
    TuiCountryIsoCode["GA"] = "GA";
    TuiCountryIsoCode["GB"] = "GB";
    TuiCountryIsoCode["GD"] = "GD";
    TuiCountryIsoCode["GE"] = "GE";
    TuiCountryIsoCode["GF"] = "GF";
    TuiCountryIsoCode["GH"] = "GH";
    TuiCountryIsoCode["GI"] = "GI";
    TuiCountryIsoCode["GL"] = "GL";
    TuiCountryIsoCode["GM"] = "GM";
    TuiCountryIsoCode["GN"] = "GN";
    TuiCountryIsoCode["GP"] = "GP";
    TuiCountryIsoCode["GQ"] = "GQ";
    TuiCountryIsoCode["GR"] = "GR";
    TuiCountryIsoCode["GT"] = "GT";
    TuiCountryIsoCode["GW"] = "GW";
    TuiCountryIsoCode["GY"] = "GY";
    TuiCountryIsoCode["HK"] = "HK";
    TuiCountryIsoCode["HN"] = "HN";
    TuiCountryIsoCode["HR"] = "HR";
    TuiCountryIsoCode["HT"] = "HT";
    TuiCountryIsoCode["HU"] = "HU";
    TuiCountryIsoCode["ID"] = "ID";
    TuiCountryIsoCode["IE"] = "IE";
    TuiCountryIsoCode["IL"] = "IL";
    TuiCountryIsoCode["IN"] = "IN";
    TuiCountryIsoCode["IQ"] = "IQ";
    TuiCountryIsoCode["IR"] = "IR";
    TuiCountryIsoCode["IS"] = "IS";
    TuiCountryIsoCode["IT"] = "IT";
    TuiCountryIsoCode["JM"] = "JM";
    TuiCountryIsoCode["JO"] = "JO";
    TuiCountryIsoCode["JP"] = "JP";
    TuiCountryIsoCode["KE"] = "KE";
    TuiCountryIsoCode["KG"] = "KG";
    TuiCountryIsoCode["KH"] = "KH";
    TuiCountryIsoCode["KM"] = "KM";
    TuiCountryIsoCode["KN"] = "KN";
    TuiCountryIsoCode["KP"] = "KP";
    TuiCountryIsoCode["KR"] = "KR";
    TuiCountryIsoCode["KW"] = "KW";
    TuiCountryIsoCode["KY"] = "KY";
    TuiCountryIsoCode["KZ"] = "KZ";
    TuiCountryIsoCode["LA"] = "LA";
    TuiCountryIsoCode["LB"] = "LB";
    TuiCountryIsoCode["LC"] = "LC";
    TuiCountryIsoCode["LI"] = "LI";
    TuiCountryIsoCode["LK"] = "LK";
    TuiCountryIsoCode["LR"] = "LR";
    TuiCountryIsoCode["LS"] = "LS";
    TuiCountryIsoCode["LT"] = "LT";
    TuiCountryIsoCode["LU"] = "LU";
    TuiCountryIsoCode["LV"] = "LV";
    TuiCountryIsoCode["LY"] = "LY";
    TuiCountryIsoCode["MA"] = "MA";
    TuiCountryIsoCode["MC"] = "MC";
    TuiCountryIsoCode["MD"] = "MD";
    TuiCountryIsoCode["ME"] = "ME";
    TuiCountryIsoCode["MF"] = "MF";
    TuiCountryIsoCode["MG"] = "MG";
    TuiCountryIsoCode["MK"] = "MK";
    TuiCountryIsoCode["ML"] = "ML";
    TuiCountryIsoCode["MM"] = "MM";
    TuiCountryIsoCode["MN"] = "MN";
    TuiCountryIsoCode["MO"] = "MO";
    TuiCountryIsoCode["MQ"] = "MQ";
    TuiCountryIsoCode["MR"] = "MR";
    TuiCountryIsoCode["MS"] = "MS";
    TuiCountryIsoCode["MT"] = "MT";
    TuiCountryIsoCode["MU"] = "MU";
    TuiCountryIsoCode["MV"] = "MV";
    TuiCountryIsoCode["MW"] = "MW";
    TuiCountryIsoCode["MX"] = "MX";
    TuiCountryIsoCode["MY"] = "MY";
    TuiCountryIsoCode["MZ"] = "MZ";
    TuiCountryIsoCode["NA"] = "NA";
    TuiCountryIsoCode["NC"] = "NC";
    TuiCountryIsoCode["NE"] = "NE";
    TuiCountryIsoCode["NG"] = "NG";
    TuiCountryIsoCode["NI"] = "NI";
    TuiCountryIsoCode["NL"] = "NL";
    TuiCountryIsoCode["NO"] = "NO";
    TuiCountryIsoCode["NP"] = "NP";
    TuiCountryIsoCode["NZ"] = "NZ";
    TuiCountryIsoCode["OM"] = "OM";
    TuiCountryIsoCode["PA"] = "PA";
    TuiCountryIsoCode["PE"] = "PE";
    TuiCountryIsoCode["PF"] = "PF";
    TuiCountryIsoCode["PG"] = "PG";
    TuiCountryIsoCode["PH"] = "PH";
    TuiCountryIsoCode["PK"] = "PK";
    TuiCountryIsoCode["PL"] = "PL";
    TuiCountryIsoCode["PT"] = "PT";
    TuiCountryIsoCode["PW"] = "PW";
    TuiCountryIsoCode["PY"] = "PY";
    TuiCountryIsoCode["QA"] = "QA";
    TuiCountryIsoCode["RE"] = "RE";
    TuiCountryIsoCode["RO"] = "RO";
    TuiCountryIsoCode["RS"] = "RS";
    TuiCountryIsoCode["RU"] = "RU";
    TuiCountryIsoCode["RW"] = "RW";
    TuiCountryIsoCode["SA"] = "SA";
    TuiCountryIsoCode["SB"] = "SB";
    TuiCountryIsoCode["SC"] = "SC";
    TuiCountryIsoCode["SD"] = "SD";
    TuiCountryIsoCode["SE"] = "SE";
    TuiCountryIsoCode["SG"] = "SG";
    TuiCountryIsoCode["SH"] = "SH";
    TuiCountryIsoCode["SI"] = "SI";
    TuiCountryIsoCode["SK"] = "SK";
    TuiCountryIsoCode["SL"] = "SL";
    TuiCountryIsoCode["SM"] = "SM";
    TuiCountryIsoCode["SN"] = "SN";
    TuiCountryIsoCode["SO"] = "SO";
    TuiCountryIsoCode["SR"] = "SR";
    TuiCountryIsoCode["ST"] = "ST";
    TuiCountryIsoCode["SV"] = "SV";
    TuiCountryIsoCode["SX"] = "SX";
    TuiCountryIsoCode["SY"] = "SY";
    TuiCountryIsoCode["SZ"] = "SZ";
    TuiCountryIsoCode["TC"] = "TC";
    TuiCountryIsoCode["TD"] = "TD";
    TuiCountryIsoCode["TG"] = "TG";
    TuiCountryIsoCode["TH"] = "TH";
    TuiCountryIsoCode["TJ"] = "TJ";
    TuiCountryIsoCode["TL"] = "TL";
    TuiCountryIsoCode["TM"] = "TM";
    TuiCountryIsoCode["TN"] = "TN";
    TuiCountryIsoCode["TO"] = "TO";
    TuiCountryIsoCode["TR"] = "TR";
    TuiCountryIsoCode["TT"] = "TT";
    TuiCountryIsoCode["TW"] = "TW";
    TuiCountryIsoCode["TZ"] = "TZ";
    TuiCountryIsoCode["UA"] = "UA";
    TuiCountryIsoCode["UG"] = "UG";
    TuiCountryIsoCode["US"] = "US";
    TuiCountryIsoCode["UY"] = "UY";
    TuiCountryIsoCode["UZ"] = "UZ";
    TuiCountryIsoCode["VC"] = "VC";
    TuiCountryIsoCode["VE"] = "VE";
    TuiCountryIsoCode["VG"] = "VG";
    TuiCountryIsoCode["VN"] = "VN";
    TuiCountryIsoCode["VU"] = "VU";
    TuiCountryIsoCode["WS"] = "WS";
    TuiCountryIsoCode["XK"] = "XK";
    TuiCountryIsoCode["YE"] = "YE";
    TuiCountryIsoCode["YT"] = "YT";
    TuiCountryIsoCode["ZA"] = "ZA";
    TuiCountryIsoCode["ZM"] = "ZM";
    TuiCountryIsoCode["ZW"] = "ZW";
})(TuiCountryIsoCode || (TuiCountryIsoCode = {}));

/**
 * Generated bundle index. Do not edit.
 */

export { TuiCountryIsoCode };

