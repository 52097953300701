import {Injectable} from '@angular/core';
import {
    ListResponse,
    News,
    NewsBySport,
    NewsDetails,
    QueryParameters,
} from '@nx-angular-standalone/shared/model';
import {removeEmptyValues} from '@nx-angular-standalone/shared/util';
import {Observable} from 'rxjs';

import {NewsList} from '../../../../model/src/interface/news';
import {BaseCrudApi} from './base-crud.api';

@Injectable({providedIn: 'root'})
export class NewsApi extends BaseCrudApi<ListResponse<News>> {
    protected readonly suffix = 'web/news';

    getNewsBySport(params: Partial<QueryParameters> = {}): Observable<NewsBySport[]> {
        return this.http.get<NewsBySport[]>(
            `${this.environment.baseUrl}/${this.suffix}/by-sport`,
            {
                params,
                headers: this.headers,
            },
        );
    }

    getNewsDetailsById(
        id: number,
        params: Partial<QueryParameters> = {},
    ): Observable<NewsDetails> {
        return this.http.get<NewsDetails>(
            `${this.environment.baseUrl}/${this.suffix}/${id}/details`,
            {
                params: removeEmptyValues(params),
                headers: this.headers,
            },
        );
    }

    getNewsList(params: Partial<QueryParameters> = {}): Observable<NewsList> {
        return this.http.get<NewsList>(
            `${this.environment.baseUrl}/${this.suffix}/list`,
            {
                params: removeEmptyValues(params),
                headers: this.headers,
            },
        );
    }
}
