export function removeEmptyValues(o: Record<string, any>): Record<string, any> {
    return Object.entries(o).reduce((res, prop) => {
        const [key, value] = prop;

        if (value !== null && value !== undefined && value !== '') {
            res[key] = value;
        }

        return res;
    }, {} as any);
}
