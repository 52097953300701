import { SkipSelf, ElementRef, Optional } from '@angular/core';
import { TUI_IS_MOBILE_RES, TUI_MODE } from '@taiga-ui/core/tokens';
import { TuiModeDirective } from '@taiga-ui/core/directives/mode';
import { of } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { tuiWatch } from '@taiga-ui/cdk';

const TUI_IS_MOBILE_RES_PROVIDER = {
    provide: TUI_IS_MOBILE_RES,
    deps: [[new SkipSelf(), TUI_IS_MOBILE_RES], ElementRef],
    useFactory: (mobile$, { nativeElement }) => {
        nativeElement[`$.class._mobile`] = mobile$;
        return mobile$;
    },
};

const MODE_PROVIDER = {
    provide: TUI_MODE,
    deps: [[new Optional(), TuiModeDirective], ElementRef],
    useFactory: (mode, { nativeElement }) => {
        const mode$ = mode
            ? mode.change$.pipe(startWith(null), map(() => mode.mode))
            : of(null);
        nativeElement[`$.data-mode.attr`] = mode$;
        return mode$;
    },
};

function tuiWatchedControllerFactory(controller, cdr, destroy$) {
    controller.change$.pipe(tuiWatch(cdr), takeUntil(destroy$)).subscribe();
    return controller;
}

/**
 * Generated bundle index. Do not edit.
 */

export { MODE_PROVIDER, TUI_IS_MOBILE_RES_PROVIDER, tuiWatchedControllerFactory };

